import { useEffect, useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { getProfile, getUsers, updateUserBalance } from '../store/actions/app.action'

export const UserBalancePage = () => {
  const [users, setUsers] = useState([])
  const [profile, setProfile] = useState()
  const [data, setData] = useState({
    type: 'Add'
  })
  const roles = ['superadmin']

  useEffect(() => {
    getProfile().then((res) => setProfile(res.data.data))
    getUsers().then((res) => setUsers(res.data.data))
  }, [])

  const onUpdate = () => {
    if (!data.user || !data.amount || data.amount < 1) {
      toast.error('Please check fields and correct your entries.')
      return
    }

    updateUserBalance({
      userId: data.user.value,
      amount: data.type === 'Deduct' ? data.amount * -1 : data.amount,
      remarks: data.remarks
    }).then((res) => {
      if (res.data.status === 'success') {
        toast.success('Balance updated')
        setTimeout(() => location.reload(), 500)
      } else {
        toast.error('Error encountered updating balance')
      }
    })
  }

  return (
    <div>
      {profile && roles.includes(profile.type) && (
        <div className="w-[40%] ml-5">
          <div>
            <span>Users</span>
            <Select
              className="mb-4 mt-5 rounded-xl "
              options={users.map((e) => ({
                label: `[${e.id}] - ${e.mobileNumber} ${e.firstname ? `- ${e.firstname} ${e.lastname}` : ''} ${e.email ? `- ${e.email}` : ''}`,
                value: e.id
              }))}
              placeholder="Select User..."
              value={data.user ?? {}}
              onChange={(value) => setData({ ...data, user: value })}
            />
          </div>
          <div className="mt-5">
            <span>Type</span>
            <div className="flex flex-row mt-5">
              <div
                className="align-center x-space-2 mr-20 pl-10 cursor-pointer"
                onClick={() => setData({ ...data, type: 'Add' })}>
                <input type="checkbox" checked={data.type === 'Add'} onChange={() => {}} /> Add
              </div>
              <div
                className="x-space-2 cursor-pointer"
                onClick={() => setData({ ...data, type: 'Deduct' })}>
                <input type="checkbox" checked={data.type === 'Deduct'} onChange={() => {}} />{' '}
                Deduct
              </div>
            </div>
          </div>
          <div className="mt-5">
            <span>Amount</span>
            <input
              onChange={(e) => setData({ ...data, amount: e.target.value })}
              className="w-full text-xl mt-5 py-2 border border-gray-300 
                  focus:outline-none focus:border-indigo-500 focus:border-2 pl-5 h-16"
              type="number"
              placeholder="Enter Amount"
              value={data.amount ?? ''}
              min={1}
            />
          </div>
          <div className="mt-5">
            <span>Remarks</span>
            <textarea
              onChange={(e) => setData({ ...data, remarks: e.target.value })}
              className="w-full text-xl mt-5 py-2 border border-gray-300 
                  focus:outline-none focus:border-indigo-500 focus:border-2 pl-5"
              type="number"
              placeholder="Enter Remarks"
              value={data.remarks ?? ''}
              rows={5}
            />
          </div>
          <div>
            <button
              onClick={() => onUpdate()}
              className="bg-secondary-800 text-gray-100 p-4 w-[320px] rounded-full tracking-wide
                        font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                        shadow-lg mt-10">
              Update Balance
            </button>
          </div>
        </div>
      )}

      {profile && !roles.includes(profile.type) && (
        <span style={{ color: 'red' }}>Not Authorized!!!</span>
      )}
    </div>
  )
}
