import React, { useState } from 'react';
import { Button, Form, Select } from 'antd';
import { updateAdminUser } from '../../../../store/actions/app.action';

interface CustomProps {
  role: Record<string, string>;
  roles: Record<string, string>[];
  profile: Record<string, any>;
}

export const AccountTypeButton: React.FC<CustomProps> = ({
  role,
  roles,
  profile,
}: Partial<CustomProps>) => {
  const [hover, setHover] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onUpdateRole = (data: any) => {
    if (!data.role) setHover(false);
    else {
      setLoading(true);
      updateAdminUser(profile?.id, {
        role: data.role,
      })
        .then((res) => {
          if (res.data?.status === 'success') {
            window.location.reload();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="relative" onMouseLeave={() => setHover(false)}>
      <Button
        className="border-0 w-fit p-0 mt-1 text-text-link text-md"
        onClick={() => setHover(true)}>
        <span className="text-blue-600 font-semibold">{role?.name || '<Not Set>'}</span>
      </Button>
      {!!hover && (
        <div className="">
          <div
            className="fixed inset-0 z-[99] bg-gray-100/50 backdrop-blur-[1px]"
            onClick={() => setHover(false)}></div>
          <div className="absolute flex flex-col border-gray-300 border-2 rounded-xl p-3 bg-white z-[99]">
            <div className="flex flex-row justify-end mx-2">
              <Button
                className="border-0 w-fit p-0 mt-1 text-text-link text-md"
                onClick={() => alert('No history available!')}>
                <span className="text-gray-500 font-semibold underline">history</span>
              </Button>
            </div>
            <div className="flex flex-col my-5 w-fit">
              <div className="flex flex-row">
                <span className="px-2 text-md font-semibold">User</span>
                <span className="px-2 text-md">:</span>
                <span className="px-2 text-md">{profile?.name || ''}</span>
              </div>
              <div className="flex flex-row">
                <span className="px-2 text-md font-semibold">User ID</span>
                <span className="px-2 text-md">:</span>
                <span className="px-2 text-md">{profile?.id || ''}</span>
              </div>
            </div>
            <Form
              className="flex flex-col m-auto font-roboto px-2 my-2 space-y-5"
              layout="vertical"
              onFinish={onUpdateRole}
              labelCol={{
                className: 'font-roboto',
              }}
              autoComplete="off">
              <Form.Item
                label="Account Type"
                name="role"
                className="p-0 m-0 mb-3 font-medium"
                initialValue={role?.id}>
                <Select
                  showSearch={true}
                  className="min-w-[450px]"
                  options={roles?.map((e) => ({
                    value: e.value,
                    label: e.text,
                  }))}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              <div className="flex flex-row space-x-3">
                <Button
                  className="text-white bg-blue-700 w-fit"
                  htmlType="submit"
                  loading={loading}>
                  Update
                </Button>
                <Button className="text-white w-fit" onClick={() => setHover(false)}>
                  <span className="text-gray-600">Cancel</span>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};
