import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getAdminRoles, getAdminUsers, getProfile } from '../../../store/actions/app.action';
import { AccountTypeButton } from './components/account-type-button.component';
import { UserModalComponent } from './components/user-modal.component';

interface DataType {
  key: React.Key;
  name: string;
  username: string;
  role: Record<string, any>;
  createdDate: string;
}

export const AdminUserList: React.FC = () => {
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState<Record<string, any>>();
  const [adminOptions, setAdminOptions] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const [newUserModalVisible, setNewUserModalVisible] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      filters: data
        .map((e: any) => ({
          text: e.name,
          value: e.name,
        }))
        .filter((v, i, a) => a.findIndex((v2) => v2.value === v.value) === i),
      onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
      filterSearch: true,
      sorter: (a: DataType, b: DataType) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Username',
      dataIndex: 'username',
      filters: data.map((e: any) => ({
        text: e.username,
        value: e.username,
      })),
      onFilter: (value: any, record: any) => record.username.indexOf(value) === 0,
      filterSearch: true,
      sorter: (a: DataType, b: DataType) =>
        a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Account Type',
      dataIndex: 'roleName',
      filters: adminOptions,
      filterSearch: true,
      onFilter: (value: any, record: any) => record.role?.id === value,
      render: (roleName: string, record: DataType) => (
        <AccountTypeButton role={record.role} roles={adminOptions ?? []} profile={record || {}} />
      ),
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      sorter: (a: DataType, b: DataType) => (a.createdDate > b.createdDate ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  useEffect(() => {
    getProfile().then((res) => {
      setProfile(res.data.data);
      setInitLoading(false);
    });
    getAdminRoles().then((res) => {
      setAdminOptions(
        res.data.data.map((e: any) => ({
          value: e.id,
          text: e.name,
        })),
      );
      setAllRoles(res.data.data);
    });
  }, []);

  useEffect(() => {
    getAdminUsers().then((res) => {
      if (res.data?.status === 'success') {
        setData(
          res.data.data.map((e: any) => {
            return {
              createdDate: moment(e.createTs).local().format('YYYY-MM-DD HH:mm:ss'),
              id: e.id,
              key: e.id,
              name: e.name,
              username: e.username,
              profilePhoto: e.profilePhoto,
              type: e.type,
              createdBy: e.createdBy?.name,
              role: e.role,
              roleName: e.role?.name,
            };
          }),
        );
      }
    });
  }, []);

  return (
    <div>
      {!initLoading && profile?.role?.name === 'superadmin' && (
        <div className="flex flex-col">
          <div className="flex flex-row justify-between mx-10 my-10 items-center">
            <div>
              <span className="font-semibold mr-5">Total Admin:</span>
              <span className="font-bold">{data.length}</span>
            </div>
            <div>
              <Button
                className="flex flex-row items-center text-white bg-blue-700 w-fit"
                onClick={() => setNewUserModalVisible(true)}
                icon={<PlusOutlined />}>
                Add
              </Button>
            </div>
          </div>
          <div className="flex flex-col mx-20">
            <Table columns={columns} dataSource={data} />
          </div>
          <UserModalComponent
            roles={allRoles}
            visible={newUserModalVisible}
            onClose={() => setNewUserModalVisible(false)}
          />
        </div>
      )}
    </div>
  );
};
