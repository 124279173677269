import { useEffect, useState } from 'react'
import { Button } from '../../components/button.component'
import { DataTable } from '../../components/datatable.component'
import { Modal } from '../../components/modal.component'
import {
  createTicketTypeCategory,
  getTicketTypeCategory,
  updateTicketTypeCategoryStatus
} from '../../store/actions/app.action'
import { toast } from 'react-toastify'
import { textFilter } from 'react-bootstrap-table2-filter'
// import * as moment from 'moment'
import Select from 'react-select'

const FloatingModal = ({
  show = false,
  isNew,
  onClose,
  onSave,
  processing = false,
  selectedItem,
  statusOptions,
  onInputChange,
  ticketTypes
}) => {
  return (
    <Modal show={show} onClose={onClose} className="items-center justify-center">
      <div className="w-auto min-w-[400px]">
        <div className="modal-header flex items-center justify-center p-4 border-b border-gray-200">
          <h5 className="text-5xl font-bold leading-normal text-gray-800">Ticket and Category</h5>
        </div>

        <div className="p-4 m-10">
          <div className="grid items-baseline ">
            <div className="grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">Type: </label>
              <input
                name="type"
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                disabled={!isNew}
                list="ticket-types"
                defaultValue={selectedItem.type}
                onChange={onInputChange}
              />
              <datalist id="ticket-types">
                {ticketTypes.map((e) => (
                  <option key={e} value={e}></option>
                ))}
              </datalist>
            </div>
            <div className="grid grid-cols-2 items-baseline mt-3">
              <label className="block font-medium text-gray-600 whitespace-nowrap">Category</label>
              <input
                name="category"
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                disabled={!isNew}
                defaultValue={selectedItem.category}
                onChange={onInputChange}
              />
            </div>
            <div className="grid grid-cols-2 items-baseline mt-3">
              <label className="block font-medium text-gray-600">Status: </label>
              <Select
                name="status"
                className="basic-single rounded-l"
                classNamePrefix="select"
                defaultValue={
                  selectedItem.status === 'active' ? statusOptions[0] : statusOptions[1]
                }
                onChange={onInputChange}
                options={statusOptions}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'black'
                  }
                })}
              />
            </div>
          </div>
        </div>

        <div className="my-8 mx-24 flex flex-row justify-center">
          <Button
            text="SAVE"
            variant="primary"
            onClick={onSave}
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            disabled={processing}
          />
          <Button
            text="CANCEL"
            variant="secondary"
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  )
}

export const TicketTypeCategoryPage = () => {
  const [data, setData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const rowClasses = ''
  const events = {
    onClick: (e, row) => {
      setIsNew(false)
      setSelectedItem(row)
      setIsModalVisible(true)
    }
  }

  const onInputChange = (e) => {
    if (e.value) {
      selectedItem.status = e.value
    } else {
      selectedItem[e.target.name] = e.target.value
    }
  }

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ]

  const tableFields = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'type',
      text: 'Type',
      filter: textFilter()
    },
    {
      dataField: 'category',
      text: 'Category',
      filter: textFilter()
    },
    {
      dataField: 'status',
      text: 'Status',
      filter: textFilter(),
      formatter: (cell) => (
        <span
          className={`border-2 px-3 py-1 ${
            cell === 'active' ? 'border-green-600' : 'border-red-600'
          } rounded-full`}>
          {cell}
        </span>
      )
    },
    {
      dataField: 'createdBy',
      text: 'Created By',
      filter: textFilter()
    }
  ]

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const onSave = () => {
    setIsProcessing(true)

    if (isNew) {
      if (!selectedItem.type || !selectedItem.category) {
        toast.error('Enter type and category')
        setIsProcessing(false)
      }

      createTicketTypeCategory({
        type: selectedItem.type,
        category: selectedItem.category,
        status: selectedItem.status
      })
        .then(() => {
          fetchTypesAndCategory()
        })
        .finally(() => {
          setIsProcessing(false)
          setIsModalVisible(false)
        })
    } else {
      updateTicketTypeCategoryStatus({
        id: selectedItem.id,
        status: selectedItem.status
      })
        .then(() => {
          fetchTypesAndCategory()
        })
        .finally(() => {
          setIsProcessing(false)
          setIsModalVisible(false)
        })
    }
  }

  const fetchTypesAndCategory = () => {
    setData([])
    getTicketTypeCategory().then((res) => {
      if (res.data?.status === 'success') {
        setData(
          res.data.data.map((e) => {
            return {
              id: e.id,
              type: e.type,
              category: e.category,
              createdBy: e.createdBy?.username,
              status: e.status
            }
          })
        )
      }
    })
  }

  useEffect(() => {
    fetchTypesAndCategory()
  }, [])

  return (
    <div className="flex bg-gray-100 text-gray-800">
      <div className="flex w-full justify-center flex-col">
        <Button
          text="New Type And Category"
          variant="secondary"
          buttonStyle="w-[250px] mb-4 rounded-xl"
          icon="add"
          onClick={() => {
            setIsNew(true)
            setSelectedItem({ status: 'active' })
            setIsModalVisible(true)
          }}
        />
        <DataTable data={data} fields={tableFields} rowClasses={rowClasses} rowEvents={events} />
        <FloatingModal
          isNew={isNew}
          show={isModalVisible}
          onClose={closeModal}
          onSave={onSave}
          processing={isProcessing}
          selectedItem={selectedItem}
          statusOptions={statusOptions}
          onInputChange={onInputChange}
          ticketTypes={[...new Set(data.map((e) => e.type))]}
        />
      </div>
    </div>
  )
}
