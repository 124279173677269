import React, { useEffect, useState } from 'react'
import { Button } from '../../components/button.component'
import { DataTable } from '../../components/datatable.component'
import { Modal } from '../../components/modal.component'
import { getProfile, getTicket, getTicketById, replyToTicket } from '../../store/actions/app.action'
import 'react-chat-elements/dist/main.css'
import { MessageList, Input } from 'react-chat-elements'
// import { toast } from 'react-toastify'
import { textFilter } from 'react-bootstrap-table2-filter'
import * as moment from 'moment'

const FloatingModal = ({ show = false, onClose, selectedItem, profile }) => {
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')
  const inputRef = React.createRef()

  useEffect(() => {
    setMessages(selectedItem?.messages || [])
  }, [selectedItem])

  const reply = () => {
    replyToTicket({
      id: selectedItem.id,
      message: message.trim()
    }).then(() => {
      const newMessages = [
        {
          _id: moment().format('x'),
          text: message.trim(),
          user: {
            _id: profile.id,
            name: ' ',
            avatar: profile.profilePhoto
          },
          createdAt: new Date()
        }
      ].concat(messages)

      setMessages(newMessages)
      setMessage('')
    })

    inputRef.current.value = ''
  }

  return (
    <Modal show={show} onClose={onClose} className="items-center justify-center">
      <div className="w-auto min-w-[800px]">
        <div className="modal-header flex items-center justify-center p-4 border-b border-gray-200">
          <h5 className="text-5xl font-bold leading-normal text-gray-800">Ticket</h5>
        </div>

        <div className="p-4 m-10">
          <div className="grid items-baseline ">
            <div className="grid grid-cols-2 items-baseline">
              <div className="grid grid-cols-2 items-baseline">
                <label className="block font-medium text-gray-600 whitespace-nowrap">
                  Ticket ID:
                </label>
                <input
                  name="_ticketID"
                  type="text"
                  className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                  disabled={true}
                  defaultValue={selectedItem.id}
                />
              </div>

              <div className="grid grid-cols-2 items-baseline mt-3 ml-3">
                <label className="block font-medium text-gray-600">Type: </label>
                <input
                  name="_ticketID"
                  type="text"
                  className="font-regular w-full p-2 border border-gray-300 rounded-l shadow capitalize"
                  disabled={true}
                  defaultValue={selectedItem.type}
                />
              </div>

              <div className="grid grid-cols-2 items-baseline mt-3">
                <label className="block font-medium text-gray-600 whitespace-nowrap">
                  Description:
                </label>
                <input
                  name="_description"
                  type="text"
                  className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                  disabled={true}
                  defaultValue={selectedItem.description}
                />
              </div>

              <div className="grid grid-cols-2 items-baseline mt-3 ml-3">
                <label className="block font-medium text-gray-600 whitespace-nowrap">
                  Category:
                </label>
                <input
                  name="_category"
                  type="text"
                  className="font-regular w-full p-2 border border-gray-300 rounded-l shadow capitalize"
                  disabled={true}
                  defaultValue={selectedItem.category}
                />
              </div>
            </div>

            <div className="grid grid-flow-row auto-rows-max mt-3">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                Conversation:
              </label>
              <MessageList
                className="message-list border bg-gray-100 mx-5 p-5 max-h-[450px] "
                lockable={true}
                downButton={false}
                dataSource={messages.map((e) => ({
                  id: e._id,
                  avatar: e.user.avatar,
                  position: profile.id === e.user._id ? 'right' : 'left',
                  type: 'text',
                  text: e.text,
                  date: moment(e.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
                }))}
              />
              <Input
                referance={inputRef}
                className="border rounded-xl mt-4"
                placeholder="Type message here..."
                onChange={(evt) => setMessage(evt.target.value)}
                multiline={true}
                rightButtons={
                  <div className="flex flex-end justify-center">
                    <Button
                      text="Send"
                      icon="send"
                      disabled={!message}
                      variant="primary"
                      buttonStyle="min-w-[100px] rounded-full"
                      onClick={reply}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const TicketPage = () => {
  const [data, setData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [profile, setProfile] = useState({})
  const [selectedItem, setSelectedItem] = useState({})
  const rowClasses = ''
  const events = {
    onClick: (e, column, columnIndex, row) => {
      getTicketById({
        id: row.id
      }).then((res) => {
        setSelectedItem(res.data.data)
        setIsModalVisible(true)
      })
    }
  }

  const tableFields = [
    {
      dataField: 'createTs',
      text: 'Date',
      filter: textFilter(),
      events
    },
    {
      dataField: 'id',
      text: 'Ticket Id',
      filter: textFilter(),
      events
    },
    {
      dataField: 'user',
      text: 'User',
      filter: textFilter(),
      events
    },
    {
      dataField: 'type',
      text: 'Type',
      filter: textFilter(),
      events
    },
    {
      dataField: 'category',
      text: 'Category',
      filter: textFilter(),
      events
    },
    {
      dataField: 'description',
      text: 'Description',
      filter: textFilter(),
      events
    }
  ]

  const closeModal = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    getTicket().then((res) => {
      if (res.data?.status === 'success') {
        setData(
          res.data.data.map((e) => {
            return {
              id: e.id,
              createTs: moment(e.createTs).local().format('YYYY-MM-DD HH:mm:ss'),
              user: e.createdByUser?.mobileNumber,
              type: e.type,
              description: e.description,
              category: e.category
            }
          })
        )
      }
    })

    getProfile().then((res) => {
      setSelectedItem({})
      setProfile(res.data.data)
    })
  }, [])

  return (
    <div className="flex bg-gray-100 text-gray-800">
      <div className="flex w-full justify-center flex-col">
        <DataTable data={data} fields={tableFields} rowClasses={rowClasses} />
        <FloatingModal
          show={isModalVisible}
          onClose={closeModal}
          selectedItem={selectedItem}
          profile={profile}
        />
      </div>
    </div>
  )
}
