import icons from '../assets/icons'

const ButtonColors = {
  primary:
    'bg-primary-600 text-white hover:bg-primary-500 active:bg-primary-700 disabled:bg-primary-800',
  secondary:
    'bg-secondary-600 text-white hover:bg-secondary-500 active:bg-secondary-700 disabled:bg-secondary-800'
}

export const Button = ({
  onClick = null,
  text,
  variant = 'primary',
  buttonStyle = '',
  icon,
  disabled = false
}) => {
  return (
    <button
      className={`${ButtonColors[variant]}  text-center w-full py-4 text-white text-2xl tracking-widest m-2 ${buttonStyle}`}
      onClick={onClick}
      disabled={disabled}>
      <div className="flex flex-row justify-center">
        {icon && (
          <span className="flex items-center justify-center text-lg text-white">
            <svg
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6">
              <path d={icons[icon]} />
            </svg>
          </span>
        )}
        <span className="ml-3">{text}</span>
      </div>
    </button>
  )
}
