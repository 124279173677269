import React from 'react'

export const Modal = ({ show = false, onClose = () => {}, children }) => {
  return (
    <>
      {show && (
        <div className="fixed z-[600] flex inset-0 bg-slate-500/50 justify-center">
          <div className="fixed inset-0" onClick={onClose}></div>
          <div className="z-50">
            <div className="bg-white max-h-[calc(100vh-100px)] rounded-md overflow-y-auto shadow-md mt-10">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
