import React, { useEffect, useState } from 'react'
import { DataTable } from '../../components/datatable.component'
import { getVehiclePricing, updateVehiclePricing } from '../../store/actions/app.action'
import 'react-chat-elements/dist/main.css'
import { toast } from 'react-toastify'
import { textFilter } from 'react-bootstrap-table2-filter'
import * as moment from 'moment'
import { Modal } from '../../components/modal.component'
import { Button } from '../../components/button.component'
import Select from 'react-select'

const FloatingModal = ({
  show = false,
  onClose,
  onSave,
  processing = false,
  selectedItem,
  statusOptions,
  onInputChange
}) => {
  return (
    <Modal show={show} onClose={onClose} className="items-center justify-center">
      <div className="w-auto min-w-[400px]">
        <div className="modal-header flex items-center justify-center p-4 border-b border-gray-200">
          <h5 className="text-5xl font-bold leading-normal text-gray-800">Vehicle Pricing</h5>
        </div>

        <div className="p-4 m-10">
          <div className="grid items-baseline ">
            <div className="grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">Name: </label>
              <input
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                disabled={true}
                defaultValue={selectedItem.name}
              />
            </div>

            <div className="grid grid-cols-2 items-baseline mt-3">
              <label className="block font-medium text-gray-600">Status: </label>
              <Select
                isDisabled={true}
                name="status"
                className="basic-single rounded-l"
                classNamePrefix="select"
                defaultValue={
                  selectedItem.status === 'enabled' ? statusOptions[0] : statusOptions[1]
                }
                options={statusOptions}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'black'
                  }
                })}
              />
            </div>

            <div className="grid grid-cols-2 items-baseline mt-3">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                Surge Multiplier:
              </label>
              <input
                name="surge"
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue={selectedItem.surge}
                onChange={onInputChange}
              />
            </div>

            <div className="grid grid-cols-2 items-baseline mt-3">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                Base Price:
              </label>
              <input
                name="basePrice"
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue={selectedItem.basePrice}
                onChange={onInputChange}
              />
            </div>

            <div className="grid grid-cols-2 items-baseline mt-3">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                Price Per Km:
              </label>
              <div className="flex flex-col">
                <textarea
                  name="pricePerKmText"
                  type="text"
                  className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                  defaultValue={selectedItem.pricePerKmText}
                  rows={7}
                  onChange={onInputChange}
                />
                <span className="text-base text-red-600 whitespace-nowrap">* Distance:Price</span>
              </div>
            </div>
          </div>
        </div>

        <div className="my-8 mx-24 flex flex-row justify-center">
          <Button
            text="SAVE"
            variant="primary"
            onClick={onSave}
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            disabled={processing}
          />
          <Button
            text="CANCEL"
            variant="secondary"
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  )
}

export const VehiclesPage = () => {
  const [data, setData] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [processing, setIsProcessing] = useState(false)
  const rowClasses = ''
  const events = {
    onClick: (e, row) => {
      setSelectedItem(row)
      setIsModalVisible(true)
    }
  }

  const statusOptions = [
    { value: 'enabled', label: 'Enabled' },
    { value: 'disabled', label: 'Disabled' }
  ]

  const onInputChange = (e) => {
    if (e.value) {
      selectedItem.status = e.value
    } else {
      selectedItem[e.target.name] = e.target.value
    }
  }

  const tableFields = [
    {
      dataField: 'createTs',
      text: 'Date',
      hidden: true,
      filter: textFilter()
    },
    {
      dataField: 'id',
      text: 'Vehicle Id',
      hidden: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter()
    },
    {
      dataField: 'type',
      text: 'Type',
      hidden: true,
      filter: textFilter()
    },
    {
      dataField: 'status',
      text: 'Status',
      filter: textFilter()
    },
    {
      dataField: 'basePrice',
      text: 'Base Price',
      filter: textFilter()
    },
    {
      dataField: 'surge',
      text: 'Surge',
      filter: textFilter()
    },
    {
      dataField: 'pricePerKm',
      text: 'Price Per Km',
      filter: textFilter(),
      formatter: (cell) => (
        <div className="space-y-2">
          {cell.map((e) => (
            <div key={e.distance} className="flex flex-row space-x-2">
              <span className="border-2 px-3 py-1 rounded-xl border-green-600">
                Distance: {e.distance}km
              </span>
              <span className="border-2 px-3 py-1 rounded-xl border-red-600">Price: {e.value}</span>
            </div>
          ))}
        </div>
      )
    },
    {
      dataField: 'updatedBy',
      text: 'Updated By',
      filter: textFilter()
    }
  ]

  const closeModal = () => {
    setSelectedItem({})
    setIsModalVisible(false)
  }

  const onSave = () => {
    setIsProcessing(true)

    if (isNaN(selectedItem.basePrice) || isNaN(selectedItem.surge)) {
      toast.error('Invalid basePrice/surge value and/or format')
      setIsProcessing(false)
      return
    }

    // validate pricePerKm format
    const pricePerKmFormatted = []
    for (const item of selectedItem.pricePerKmText.trim().split('\n')) {
      const itemData = item.trim().split(':')
      if (isNaN(itemData[0].trim()) || isNaN(itemData[1].trim()) || itemData.length > 2) {
        toast.error(`Invalid price per km item format: ${item}`)
        setIsProcessing(false)
        return
      }

      pricePerKmFormatted.push({
        distance: itemData[0].trim(),
        value: parseFloat(itemData[1])
      })
    }

    if (pricePerKmFormatted.length === 0) {
      toast.error('Invalid price per km')
      setIsProcessing(false)
      return
    }

    updateVehiclePricing({
      id: selectedItem.id,
      surge: parseFloat(selectedItem.surge),
      basePrice: parseFloat(selectedItem.basePrice),
      status: selectedItem.status,
      pricePerKm: pricePerKmFormatted.sort((a, b) => b.distance - a.distance)
    })
      .then(() => {
        loadData()
      })
      .finally(() => {
        setIsProcessing(false)
        setIsModalVisible(false)
      })
  }

  const loadData = () => {
    getVehiclePricing().then((res) => {
      if (res.data?.status === 'success') {
        setData(
          res.data.data.map((e) => {
            return {
              id: e.id,
              createTs: moment(e.createTs).local().format('YYYY-MM-DD HH:mm:ss'),
              name: e.name,
              type: e.type,
              status: e.status,
              basePrice: parseFloat(e.basePrice).toFixed(2),
              surge: parseFloat(e.surge).toFixed(2),
              pricePerKm: e.pricePerKm,
              pricePerKmText: e.pricePerKm.map((e) => `${e.distance}:${e.value}`).join('\n'),
              updatedBy: e.updatedBy?.username
            }
          })
        )
      }
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className="flex bg-gray-100 text-gray-800">
      <div className="flex w-full justify-center flex-col">
        <DataTable data={data} fields={tableFields} rowClasses={rowClasses} rowEvents={events} />
        <FloatingModal
          show={isModalVisible}
          onClose={closeModal}
          onSave={onSave}
          processing={processing}
          selectedItem={selectedItem}
          statusOptions={statusOptions}
          onInputChange={onInputChange}
        />
      </div>
    </div>
  )
}
