// import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  Chart,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Legend
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import { DateRangePicker } from 'react-date-range'
import { getDashboardData } from '../../store/actions/app.action'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import moment from 'moment'
import { DataTable } from '../../components/datatable.component'
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter'

Chart.register(Tooltip, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Legend)

export const HomePage = () => {
  const [refreshTimer, setRefreshTimer] = useState(0)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [dateFilter, setDateFilter] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate(),
    key: 'selection'
  })
  const [sales, setSales] = useState([])
  const [userTopup, setUserTopup] = useState([])
  const [deliveries, setDeliveries] = useState([])

  const [salesData, setSalesData] = useState({
    labels: [],
    datasets: []
  })
  const [userTopupData, setUserTopupData] = useState({
    labels: [],
    datasets: []
  })
  const [deliveriesData, setDeliveriesData] = useState({
    labels: [],
    datasets: []
  })

  const [salesTableData, setSalesTableData] = useState([])
  const [userTopupTableData, setUserTopupTableData] = useState([])

  const optPaymentType = {
    cod: 'COD',
    wallet: 'WALLET'
  }

  const optUserType = {
    Driver: 'DRIVER',
    User: 'USER'
  }

  const salesTableFields = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'createTs',
      text: 'Date'
    },
    {
      dataField: 'user',
      text: 'User Mobile Number',
      hidden: true,
      filter: textFilter()
    },
    {
      dataField: 'driver',
      text: 'Driver Mobile Number',
      hidden: true,
      filter: textFilter()
    },
    {
      dataField: 'paymentType',
      text: 'Payment Type',
      formatter: (cell) => optPaymentType[cell],
      filter: selectFilter({
        options: optPaymentType
      })
    },
    {
      dataField: 'totalPrice',
      text: 'Total Price'
    },
    {
      dataField: 'price',
      text: 'Price'
    },
    {
      dataField: 'commissionRate',
      text: 'Commission Rate'
    },
    {
      dataField: 'profit',
      text: 'Profit'
    },
    {
      dataField: 'driverCommission',
      text: 'Driver Commission'
    },
    {
      dataField: 'waitingCharge',
      text: 'Waiting Charge'
    },
    {
      dataField: 'waitingChargeProfit',
      text: 'Waiting Charge Profit'
    },
    {
      dataField: 'waitingChargeCommission',
      text: 'Waiting Charge Commission'
    },
    {
      dataField: 'totalDriverCommission',
      text: 'Total Driver Commission'
    },
    {
      dataField: 'totalProfit',
      text: 'Total Profit'
    },
  ]
  const userTopupTableFields = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'createTs',
      text: 'Date'
    },
    {
      dataField: 'reference',
      text: 'Reference',
      filter: textFilter()
    },
    {
      dataField: 'mobilenumber',
      text: 'Mobile',
      hidden: true,
      filter: textFilter()
    },
    {
      dataField: 'uservalue',
      text: 'User Type',
      hidden: true,
      formatter: (cell) => optUserType[cell],
      filter: selectFilter({
        options: optUserType
      })
    },
    {
      dataField: 'description',
      text: 'Description',
      filter: textFilter()
    },
    {
      dataField: 'price',
      text: 'Price'
    },
    {
      dataField: 'value',
      text: 'Value'
    },
    {
      dataField: 'beforebalance',
      text: 'Before Balance',
      hidden: true
    },
    {
      dataField: 'afterbalance',
      text: 'After Balance',
      hidden: true
    }
  ]

  const chartOpts = {
    interaction: {
      mode: 'index',
      intersect: false,
    },
    // responsive: true,
    // plugins: {
    //   legend: {
    //     position: 'top',
    //   },
    // },
    // scales: {
    //   y: {
    //     beginAtZero: true
    //   }
    // }
  }

  // useEffect(() => {
  //   const dataUpdate = setTimeout(() => {
  //     if (refreshTimer > 0) {
  //       setRefreshTimer(refreshTimer - 1)
  //     } else {
  //       updateData()
  //     }
  //   }, 1000)

  //   return () => clearTimeout(dataUpdate)
  // }, [dateFilter, refreshTimer])

  const updateData = () => {
    getDashboardData({
      startDate: moment(dateFilter.startDate).format('YYYY-MM-DD'),
      endDate: moment(dateFilter.endDate).format('YYYY-MM-DD')
    })
      .then((res) => {
        setSales(res.data.data.sales)
        setUserTopup(res.data.data.userTopup)
        setDeliveries(res.data.data.deliveries)
      })
      .finally(() => {
        setRefreshTimer(60)
      })
  }

  const calc = (val) => {
    let num = val
    let with2Decimal = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    return with2Decimal.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
  }

  // Sales
  useEffect(() => {
    const dates = []
    let datesData = {}

    for (
      const d = new Date(dateFilter.startDate);
      d <= dateFilter.endDate;
      d.setDate(d.getDate() + 1)
    ) {
      dates.push(moment(d).format('YYYY-MM-DD'))
      datesData[moment(d).format('YYYY-MM-DD')] = 0
    }

    const salesLineData = sales.reduce(
      (retData, e) => {
        const dateKey = moment(e.createTs).local().format('YYYY-MM-DD')

        if (retData.profit[dateKey] === undefined) {
          retData.profit[dateKey] = 0
        }
        if (retData.driverCommission[dateKey] === undefined) {
          retData.driverCommission[dateKey] = 0
        }
        if (retData.totalPrice[dateKey] === undefined) {
          retData.totalPrice[dateKey] = 0
        }

        const waitingChargeCommission =
          parseFloat(e.delivery.waitingCharge) * (parseFloat(e.commissionRate) / 100)
        const profit =
          parseFloat(e.profit) + parseFloat(e.delivery.waitingCharge) - waitingChargeCommission
        const driverCommission = parseFloat(e.driverCommission) + waitingChargeCommission

        retData.profit[dateKey] += profit
        retData.driverCommission[dateKey] += driverCommission
        retData.totalPrice[dateKey] += parseFloat(e.delivery.price)
        return retData
      },
      { profit: { ...datesData }, driverCommission: { ...datesData }, totalPrice: { ...datesData } }
    )

    setSalesData({
      labels: dates,
      datasets: [
        {
          label: 'Total Profit',
          data: Object.values(salesLineData.profit),
          fill: true,
          borderColor: [
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`,
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`
          ]
        },
        {
          label: 'Driver Commission',
          data: Object.values(salesLineData.driverCommission),
          fill: true,
          borderColor: [
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`,
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`
          ]
        },
        {
          label: 'Total Price',
          data: Object.values(salesLineData.totalPrice),
          fill: true,
          borderColor: [
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`,
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`
          ]
        }
      ]
    })

    setSalesTableData(
      sales.map((e) => {
        const waitingCommission =
          parseFloat(e.delivery.waitingCharge) * (parseFloat(e.commissionRate) / 100)
        return {
          id: e.id,
          createTs: moment(e.createTs).local().format('YYYY-MM-DD HH:mm:ss'),
          price: calc(e.delivery.price),
          user: e.delivery?.createdBy?.mobileNumber,
          driver: e.delivery?.driver?.mobileNumber,
          commissionRate: calc(e.commissionRate),
          totalPrice: calc(parseFloat(e.delivery.price) + parseFloat(e.delivery.waitingCharge)),
          profit: calc(parseFloat(e.profit) + waitingCommission),
          driverCommission: calc(parseFloat(e.driverCommission) - waitingCommission),
          paymentType: e.delivery.paymentType,
          waitingCharge: calc(parseFloat(e.delivery.waitingCharge)),
          waitingChargeProfit: calc(parseFloat(e.delivery.waitingCharge) - waitingCommission),
          waitingChargeCommission: calc(waitingCommission),
          totalDriverCommission: calc(parseFloat(e.driverCommission)),
          totalProfit: calc((parseFloat(e.delivery.price) + parseFloat(e.delivery.waitingCharge)) - parseFloat(e.driverCommission)),
        }
      })
    )
  }, [sales])

  // User Topup
  useEffect(() => {
    const dates = []
    let datesData = {}

    for (
      const d = new Date(dateFilter.startDate);
      d <= dateFilter.endDate;
      d.setDate(d.getDate() + 1)
    ) {
      dates.push(moment(d).format('YYYY-MM-DD'))
      datesData[moment(d).format('YYYY-MM-DD')] = 0
    }

    const userTopupLineData = userTopup.reduce(
      (retData, e) => {
        const dateKey = moment(e.createTs).local().format('YYYY-MM-DD')

        if (retData[dateKey] === undefined) {
          retData[dateKey] = 0
        }

        if (e.user && !e.requestId.startsWith('adm')) {
          retData[dateKey] += parseFloat(e.amount)
        }
        return retData
      },
      { ...datesData }
    )

    const driverTopupLineData = userTopup.reduce(
      (retData, e) => {
        const dateKey = moment(e.createTs).local().format('YYYY-MM-DD')

        if (retData[dateKey] === undefined) {
          retData[dateKey] = 0
        }

        if (e.driver && !e.requestId.startsWith('adm')) {
          retData[dateKey] += parseFloat(e.amount)
        }
        return retData
      },
      { ...datesData }
    )

    const userAdmTopupLineData = userTopup.reduce(
      (retData, e) => {
        const dateKey = moment(e.createTs).local().format('YYYY-MM-DD')

        if (retData[dateKey] === undefined) {
          retData[dateKey] = 0
        }

        if (e.user && e.requestId.startsWith('adm')) {
          retData[dateKey] += parseFloat(e.topupValue)
        }
        return retData
      },
      { ...datesData }
    )

    const driverAdmTopupLineData = userTopup.reduce(
      (retData, e) => {
        const dateKey = moment(e.createTs).local().format('YYYY-MM-DD')

        if (retData[dateKey] === undefined) {
          retData[dateKey] = 0
        }

        if (e.driver && e.requestId.startsWith('adm')) {
          retData[dateKey] += parseFloat(e.topupValue)
        }
        return retData
      },
      { ...datesData }
    )

    setUserTopupData({
      labels: dates,
      datasets: [
        {
          label: 'User Topup Price',
          data: Object.values(userTopupLineData),
          fill: true,
          borderColor: [
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`
          ]
        },
        {
          label: 'Driver Topup Price',
          data: Object.values(driverTopupLineData),
          fill: true,
          borderColor: [
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`
          ]
        },
        {
          label: 'Admin User Topup Value',
          data: Object.values(userAdmTopupLineData),
          fill: true,
          borderColor: [
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`
          ]
        },
        {
          label: 'Admin Driver Topup Value',
          data: Object.values(driverAdmTopupLineData),
          fill: true,
          borderColor: [
            `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)})`
          ]
        },
      ]
    })

    setUserTopupTableData(
      userTopup.map((e) => {
        return {
          id: e.id,
          createTs: moment(e.createTs).local().format('YYYY-MM-DD HH:mm:ss'),
          reference: e.reference,
          description: e.description,
          price: calc(e.amount),
          value: calc(e.topupValue),
          uservalue: e.driver === null ? 'User' : 'Driver',
          mobilenumber: e.driver === null ? e.user.mobileNumber : e.driver.mobileNumber,
          beforebalance: calc(e.beforeBalance),
          afterbalance: calc(e.afterBalance)
        }
      })
    )
  }, [userTopup])

  // Deliveries
  useEffect(() => {
    const dData = deliveries.reduce(
      (dData, record) => {
        if (
          ['pickup', 'arrived_at_pickup', 'in_transit', 'arrived_at_drop'].includes(record.status)
        ) {
          dData['in_transit']++
        } else {
          dData[record.status]++
        }
        dData.total++
        return dData
      },
      { waiting: 0, in_transit: 0, completed: 0, cancelled: 0 }
    )

    const barColor = [
      `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )})`,
      `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )})`,
      `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )})`,
      `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )})`
    ]

    setDeliveriesData({
      labels: ['Waiting', 'In Transit', 'Completed', 'Cancelled'],
      datasets: [
        {
          label: 'Deliveries',
          data: [dData.waiting, dData.in_transit, dData.completed, dData.cancelled],
          backgroundColor: barColor,
          borderColor: barColor
        }
      ]
    })
  }, [deliveries])

  useEffect(() => {
    if (dateFilter.startDate && dateFilter.endDate) {
      updateData()
    }
  }, [dateFilter])

  useEffect(() => {
    setTimeout(updateData, 1000)
  }, [dateFilter])

  return (
    <div className="bg-gray-100 text-gray-800">
      <div
        className="flex-none w-fit"
        onMouseEnter={() => setShowDatePicker(true)}
        onMouseLeave={() => setShowDatePicker(false)}>
        <span className="font-semibold mr-3">Select Date Range:</span>
        <input
          className="w-[240px] border p-2 rounded-lg"
          value={`${moment(dateFilter.startDate).format('YYYY-MM-DD')} ~ ${moment(
            dateFilter.endDate
          ).format('YYYY-MM-DD')}`}
          readOnly
        />
        {showDatePicker && (
          <DateRangePicker
            className="z-50 absolute"
            ranges={[dateFilter]}
            onChange={(item) => setDateFilter(item.selection)}
          />
        )}
      </div>

      <div className="flex flex-row mt-5 justify-center mx-10">
        <div className="w-1/2">
          <div className="flex justify-center my-3">
            <span className="font-bold text-md">Sales</span>
          </div>
          <Line className="" data={salesData} redraw={true} options={chartOpts} />
        </div>
        <div className="w-1/2">
          <div className="flex justify-center my-3">
            <span className="font-bold text-md">Topup</span>
          </div>
          <Line className="" data={userTopupData} redraw={true} options={chartOpts} />
        </div>
      </div>

      <div className="flex flex-row mt-5 justify-center">
        <div className="w-1/2">
          <div className="flex justify-center my-3">
            <span className="font-bold text-md">Deliveries</span>
          </div>
          <Bar data={deliveriesData} redraw={true} options={chartOpts} />
        </div>
      </div>

      <div className="mt-10 mx-20">
        <div>
          <div className="flex my-3">
            <span className="font-bold text-3xl">Sales</span>
          </div>
          <div className="mx-10">
            <DataTable data={salesTableData} fields={salesTableFields} />
          </div>
        </div>
        <div>
          <div className="flex my-3">
            <span className="font-bold text-3xl">User Topup</span>
          </div>
          <div className="mx-10">
            <DataTable data={userTopupTableData} fields={userTopupTableFields} />
          </div>
        </div>
      </div>
    </div>
  )
}
