import { useEffect, useState } from 'react'
import { Button } from '../../components/button.component'
import { DataTable } from '../../components/datatable.component'
import { Modal } from '../../components/modal.component'
import {
  postAdminTopup,
  getAdminTopup,
  putAdminTopup,
  delAdminTopup
} from '../../store/actions/app.action'
import { toast } from 'react-toastify'
import { textFilter } from 'react-bootstrap-table2-filter'

const FloatingModal = ({
  show = false,
  onClose,
  onConfirm,
  onDelete,
  processing = false,
  selectedItem,
  isAddUser,
  getInputValue
}) => {
  return (
    <Modal show={show} onClose={onClose} className="items-center justify-center">
      <div className="w-auto min-w-[400px]">
        <div className="modal-header flex items-center justify-center p-4 border-b border-gray-200">
          <h5 className="text-5xl font-bold leading-normal text-gray-800">
            {isAddUser ? 'Add New Topups' : 'Update Topup'}
          </h5>
        </div>

        <div className="p-4 m-10">
          <div className="grid items-baseline ">
            <div className="grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">Name: </label>
              <input
                name="_name"
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                // disabled={!isAddUser}
                defaultValue={isAddUser ? '' : selectedItem.name}
                onChange={getInputValue}
              />
            </div>
            <div className="mt-5 grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                Description:{' '}
              </label>
              <input
                name="_description"
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue={isAddUser ? '' : selectedItem.description}
                onChange={getInputValue}
              />
            </div>
            <div className="mt-5 grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">Value: </label>
              <input
                name="_value"
                type="number"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue={isAddUser ? '' : selectedItem.value}
                onChange={getInputValue}
              />
            </div>
            <div className="mt-5 grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">Price: </label>
              <input
                name="_price"
                type="number"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue={isAddUser ? '' : selectedItem.price}
                onChange={getInputValue}
              />
            </div>
          </div>
        </div>

        <div className="my-8 mx-24 flex flex-row justify-center">
          <Button
            text="SAVE"
            variant="primary"
            onClick={onConfirm}
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            disabled={processing}
          />
          <Button
            text="Delete"
            variant="primary"
            onClick={onDelete}
            buttonStyle={`bg-red-600 hover:bg-red-500 min-w-[100px] mb-5 rounded-3xl ${
              isAddUser ? 'hidden' : ''
            }`}
            disabled={processing}
          />
          <Button
            text="CANCEL"
            variant="secondary"
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  )
}

export const TopupPage = () => {
  const [data, setData] = useState([])
  const [isAddUser, setisAddUser] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [userDetails, setUserDetails] = useState({
    _name: '',
    _description: '',
    _value: '',
    _price: ''
  })

  const rowClasses = ''

  const events = {
    onClick: (e, column, columnIndex, row) => {
      setSelectedItem(row)
      setIsModalVisible(true)
      setUserDetails({
        _name: row.name,
        _description: row.description,
        _value: row.value,
        _price: row.price
      })
    }
  }

  const getInputValue = (e) => {
    userDetails[e.target.name] = e.target.value
  }

  const clearUserDetails = () => {
    setUserDetails({
      _name: '',
      _description: '',
      _value: '',
      _price: ''
    })
  }

  const tableFields = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true,
      events
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter(),
      events
    },
    {
      dataField: 'description',
      text: 'Description',
      filter: textFilter(),
      events
    },
    {
      dataField: 'price',
      text: 'Price',
      events
    },
    {
      dataField: 'value',
      text: 'Value',
      events
    },
    {
      dataField: 'updateTs',
      text: 'Updated TS',
      events
    },
    {
      dataField: 'updatedBy',
      text: 'Updated By',
      events
    }
  ]

  const closeModal = () => {
    clearUserDetails()
    setIsModalVisible(false)
    setisAddUser(false)
  }

  const validation = () => {
    let val = true
    if (userDetails['_name'] === '') {
      toast.warning('Name is empty')
      val = false
    } else if (userDetails['_description'] === '') {
      toast.warning('Description is empty')
      val = false
    } else if (userDetails['_value'] === '') {
      toast.warning('Value is empty')
      val = false
    } else if (userDetails['_price'] === '') {
      toast.warning('Price is empty')
      val = false
    } else {
      val = true
    }

    return val
  }
  const onDelete = () => {
    setIsProcessing(true)
    delAdminTopup({
      id: selectedItem.id
    })
      .then((res) => {
        if (res.data?.status === 'success') {
          toast.success('Successfully Deleted')
          closeModal()
        } else {
          toast.error('Error Deleting!')
          setIsProcessing(false)
        }
      })
      .finally(() => {
        getData()
        setIsProcessing(false)
      })
  }

  const onConfirm = () => {
    setIsProcessing(true)
    if (!validation()) {
      setIsProcessing(false)
      return
    }
    if (isAddUser) {
      postAdminTopup({
        name: userDetails['_name'],
        description: userDetails['_description'],
        value: userDetails['_value'],
        price: userDetails['_price']
      })
        .then((res) => {
          if (res.data?.status === 'success') {
            toast.success('Successfully Updated Topups')
            closeModal()
          } else {
            toast.error('Error confirming Topup!')
            setIsProcessing(false)
          }
        })
        .finally(() => {
          getData()
          setIsProcessing(false)
        })
    } else {
      putAdminTopup({
        id: selectedItem.id,
        name: userDetails['_name'],
        description: userDetails['_description'],
        value: userDetails['_value'],
        price: userDetails['_price']
      })
        .then((res) => {
          if (res.data?.status === 'success') {
            toast.success('Successfully Updated Topups')
            closeModal()
          } else {
            toast.error('Error confirming Topup!')
            setIsProcessing(false)
          }
        })
        .finally(() => {
          getData()
          setIsProcessing(false)
        })
    }
  }
  const getData = () => {
    getAdminTopup().then((res) => {
      if (res.data?.status === 'success') {
        setData(
          res.data.data.map((e) => {
            return {
              id: e.id,
              name: e.name,
              description: e.description,
              price: e.price,
              updateTs: e.updateTs,
              value: e.value,
              updatedBy: e.updatedBy?.name
            }
          })
        )
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="flex bg-gray-100 text-gray-800">
      <div className="flex w-full justify-center flex-col">
        <Button
          text="Add Topups"
          variant="secondary"
          buttonStyle="w-[125px] mb-4 rounded-xl"
          icon="add"
          onClick={() => {
            setIsModalVisible(true)
            setisAddUser(true)
          }}
        />
        <DataTable data={data} fields={tableFields} rowClasses={rowClasses} />
        <FloatingModal
          show={isModalVisible}
          onClose={closeModal}
          onConfirm={onConfirm}
          onDelete={onDelete}
          processing={isProcessing}
          selectedItem={selectedItem}
          isAddUser={isAddUser}
          getInputValue={getInputValue}
        />
      </div>
    </div>
  )
}
