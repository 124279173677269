import React, { useState } from 'react'
import icons from '../assets/icons'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'

const CustomExportCSV = (props) => {
  return (
    <div>
      <button
        className="px-3 py-2 rounded-lg border border-gray-600 flex items-center justify-center space-x-2 mb-2"
        onClick={() => props.onExport()}>
        <span className="flex items-center justify-center text-lg text-gray-700">
          <svg
            fill="rgb(0%,0%,0%)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6">
            <path d={icons.download} />
          </svg>
        </span>
        <span className="text-gray-700 font-semibold">Export</span>
      </button>
    </div>
  )
}

const CustomToggleList = ({ columns, onColumnToggle, toggles }) => {
  const [visible, setVisible] = useState(false)

  return (
    <div>
      <button
        className="px-3 py-2 rounded-lg border border-gray-600 flex items-center justify-center space-x-2 mb-2"
        onClick={() => setVisible(!visible)}>
        <span className="flex items-center justify-center text-lg text-gray-700">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6">
            <path d={icons.setting} />
          </svg>
        </span>
        <span className="text-gray-700 font-semibold">Column Filter</span>
      </button>

      <div
        className={`absolute top-0 left-0 w-screen h-screen z-[100] ${!visible ? 'hidden' : ''}`}
        onClick={() => setVisible(false)}></div>

      <div className={`absolute ml-[42px] -mt-8 z-[100] bg-white ${!visible ? 'hidden' : ''}`}>
        <div className="w-80 p-4 border">
          <h4 className="text-center">Column Filter</h4>
          <div className="flex flex-col py-2 px-2">
            {columns
              .map((column) => ({
                ...column,
                toggle: toggles[column.dataField]
              }))
              .map((column) => (
                <div
                  key={column.dataField}
                  className="flex flex-row justify-between items-center border-b border-slate-200 py-2">
                  <input
                    checked={column.toggle}
                    type="checkbox"
                    onChange={() => onColumnToggle(column.dataField)}
                  />
                  <label
                    className="ml-3 mb-0 flex-grow text-gray-700 cursor-pointer"
                    onClick={() => onColumnToggle(column.dataField)}>
                    {column.text}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const DataTable = ({ keyField = 'id', fields, data, rowClasses, rowEvents }) => {
  const paginationOpts = {
    disablePageTitle: true,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '20', value: 20 },
      { text: '50', value: 50 }
    ],
    pageStartIndex: 1
  }

  return (
    <div>
      <ToolkitProvider keyField={keyField} columns={fields} data={data} columnToggle exportCSV={{ exportAll: false, onlyExportFiltered: true }}>
        {(props) => (
          <div>
            <div className="flex flex-row space-x-2">
              <CustomExportCSV {...props.csvProps}>Export</CustomExportCSV>
              <CustomToggleList {...props.columnToggleProps} />
            </div>
            <hr />
            <BootstrapTable
              {...props.baseProps}
              rowClasses={rowClasses}
              rowEvents={rowEvents}
              striped
              hover
              pagination={paginationFactory(paginationOpts)}
              filter={filterFactory()}
              filterPosition="top"
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  )
}
