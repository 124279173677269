import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token: null,
  profile: null,
  loading: false
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

/**
 * Export functions
 */
export const { setProfile, setToken, setLoading } = appSlice.actions

/**
 * Export selectors
 */
export const selectProfile = (state) => {
  return state.app.profile
}
export const selectToken = (state) => {
  return state.app.token
}
export const selectLoading = (state) => {
  return state.app.loading
}

/**
 * Export reducer
 */
export default appSlice.reducer
