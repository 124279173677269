import { Sidebar } from './sidebar.component'
import { Footer } from './footer.component'
import { UserModal } from './user-modal.component'

export const HomeTemplate = ({ component, headerName = '' }) => {
  return (
    <div className="home-template flex flex-row min-h-screen bg-gray-100 text-gray-800">
      <Sidebar />
      <main className="main flex flex-col w-full md:ml-0 transition-all duration-150 ease-in">
        <header className="header bg-gray-100 shadow py-4 px-4">
          <div className="header-content flex items-center flex-row">
            <form action="#">
              <div className="hidden md:flex relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"></div>
              </div>

              <div className="flex md:hidden">
                <a
                  href="#"
                  className="flex items-center justify-center h-10 w-10 border-transparent">
                  <svg
                    className="h-6 w-6 text-gray-500"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </a>
              </div>
            </form>
            <div className="flex ml-auto">
              <UserModal />
            </div>
          </div>
        </header>
        <div className="main-content flex flex-col flex-grow p-4">
          <h1 className="font-bold text-2xl text-gray-700">{headerName}</h1>
          <div className="flex flex-col flex-grow rounded mt-5">{component}</div>
        </div>
        <Footer />
      </main>
    </div>
  )
}
