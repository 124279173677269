import React, { useEffect, useState } from 'react'
import icons from '../assets/icons'
import logo from '../assets/logo64x64.png'
import { getProfile } from '../store/actions/app.action'

export const Sidebar = () => {
  const navigationList = [
    {
      path: '/home',
      text: 'Dashboard',
      icon: 'manager'
    },
    {
      path: '/admin-users',
      text: 'Admin Users',
      icon: 'users',
      sub: [
        {
          path: '/admin-users',
          text: 'Admin List'
        },
        {
          path: '/admin-roles',
          text: 'Admin Roles',
          roles: ['superadmin']
        }
      ]
    },
    {
      path: '/users',
      text: 'Users',
      icon: 'users',
      sub: [
        {
          path: '/users',
          text: 'User List'
        },
        {
          path: '/users/balance',
          text: 'User Balance',
          roles: ['superadmin']
        }
      ]
    },
    {
      path: '/drivers',
      text: 'Drivers',
      icon: 'motorcycle',
      sub: [
        {
          path: '/drivers',
          text: 'Driver List'
        },
        {
          path: '/drivers/balance',
          text: 'Driver Balance',
          roles: ['superadmin']
        },
        {
          path: '/driver-location',
          text: 'Driver Location'
        },
        {
          path: '/online-applications',
          text: 'Online Application'
        }
      ]
    },
    {
      path: '/deliveries',
      text: 'Deliveries',
      icon: 'package',
      sub: [
        {
          path: '/deliveries',
          text: 'Delivery List'
        }
      ]
    },
    {
      path: '/tickets',
      text: 'Tickets',
      icon: 'task'
    },
    {
      path: '/config',
      text: 'Setting',
      icon: 'setting',
      sub: [
        {
          path: '/config',
          text: 'Config'
        },
        {
          path: '/topup',
          text: 'Topup Items'
        },
        {
          path: '/ticket-type-and-category',
          text: 'Ticket Type & Category'
        },
        {
          path: '/vehicles',
          text: 'Vehicles'
        }
      ]
    }
  ]

  const [isSub, setIsSub] = useState(false)
  const [isCurrent, setIsCurrent] = useState([])
  const [user, setUser] = useState({})

  const showSubNav = (content) => {
    if (content.sub) {
      setIsCurrent(content.text)
      setIsSub(true)
    } else {
      setIsSub(false)
    }
  }

  useEffect(() => {
    getProfile().then((res) => {
      setUser(res.data.data)
    })
  }, [])

  return (
    <div className="flex z-[100]">
      <div
        className={
          'h-full sidebar flex-none md:shadow transform -translate-x-full md:translate-x-0 bg-secondary-500 w-[200px]'
        }>
        <div className="flex items-center justify-center py-4 w-[200px]">
          <div className="inline-flex px-3 py-4 ">
            <a
              href="#"
              className="inline-flex flex-row items-center rounded-md bg-white border-[#f05722]">
              <img className="w-1/3 " src={logo} />
              <span className="leading-10 text-primary-500 text-2xl font-bold ml-1 uppercase">
                Upfront
              </span>
            </a>
          </div>
        </div>
        <div className="py-6 bg-secondary-300">
          <ul className="flex flex-col w-full ">
            {navigationList.map((e) => {
              return (
                <li
                  className=" py-1 w-[200px] hoverable text-gray-300 hover:bg-gray-100 hover:text-gray-700"
                  onMouseLeave={() => setIsSub(false)}
                  onMouseEnter={() => showSubNav(e)}
                  key={e.path}>
                  <a href={e.path} className={`${e.text} flex flex-row items-center h-10 px-4`}>
                    <span className="flex items-center justify-center text-lg text-gray-400">
                      <svg
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6">
                        <path d={icons[e.icon]} />
                      </svg>
                    </span>
                    <span className="ml-3">{e.text}</span>
                  </a>
                  <div
                    className={`mega-menu w-[200px] bg-secondary-400 ${isSub ? 'flex' : 'hidden'}`}>
                    <div className="container px-0">
                      <div className="mb-8">
                        <ul className="">
                          {navigationList.map((e) => {
                            if (e.text === isCurrent) {
                              return e.sub.map((elm) => {
                                return !elm.roles || elm.roles.includes(user.role?.name) ? (
                                  <div
                                    key={elm.text}
                                    className="flex py-2 text-gray-300 hover:bg-gray-100 hover:text-gray-700">
                                    <a href={elm.path} className="px-4">
                                      {elm.text}
                                    </a>
                                  </div>
                                ) : (
                                  <div></div>
                                )
                              })
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
