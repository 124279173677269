import { useEffect, useState } from 'react'
import { textFilter } from 'react-bootstrap-table2-filter'
import { DataTable } from '../components/datatable.component'
import { getDrivers } from '../store/actions/app.action'
import * as moment from 'moment'

export const DriversPage = () => {
  const [data, setData] = useState([])

  const tableFields = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'profilePhoto',
      text: 'Profile Photo',
      formatter: (cell) => (
        <div className="flex justify-center">
          <img src={cell} className="h-16 rounded-full" />
        </div>
      )
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter()
    },
    {
      dataField: 'mobileNumber',
      text: 'Mobile Number',
      filter: textFilter()
    },
    {
      dataField: 'email',
      text: 'Email',
      filter: textFilter()
    },
    {
      dataField: 'status',
      text: 'Status',
      filter: textFilter()
    },
    {
      dataField: 'licenseType',
      text: 'License Type',
      filter: textFilter()
    },
    {
      dataField: 'vehicleBrand',
      text: 'Vehicle Brand',
      filter: textFilter()
    },
    {
      dataField: 'vehicleMake',
      text: 'Vehicle Make',
      filter: textFilter()
    },
    {
      dataField: 'balance',
      text: 'Balance',
      filter: textFilter()
    },
    {
      dataField: 'createTs',
      text: 'Created Date'
    }
  ]

  useEffect(() => {
    getDrivers().then((res) => {
      if (res.data?.status === 'success') {
        setData(
          res.data.data.map((e) => {
            return {
              createTs: moment(e.createTs).local().format('YYYY-MM-DD HH:mm:ss'),
              id: e.id,
              name: e.name,
              mobileNumber: e.mobileNumber,
              email: e.email,
              profilePhoto: e.profilePhoto,
              status: e.isActive ? 'Online' : 'Offline',
              licenseType: e.licenseType.toUpperCase(),
              vehicleBrand: e.vehicleBrand,
              vehicleMake: e.vehicleMake,
              balance: parseFloat(e.balance).toFixed(2)
            }
          })
        )
      }
    })
  }, [])

  return (
    <div className="flex bg-gray-100 text-gray-800">
      <div className="flex w-full justify-center">
        <DataTable data={data} fields={tableFields} rowStyle={{ verticalAlign: 'middle' }} />
      </div>
    </div>
  )
}
