import { useEffect, useState } from 'react'
import { DataTable } from '../../components/datatable.component'
import { Button } from '../../components/button.component'
import { getDeliveries } from '../../store/actions/app.action'
import { Modal } from '../../components/modal.component'
import * as moment from 'moment'
import Select from 'react-select'
import { textFilter } from 'react-bootstrap-table2-filter'
import { TRACKER_URL } from '../../services/api-client.service'

const FloatingModal = ({ show = false, onClose, onDetails, selectedItem }) => {
  return (
    <Modal show={show} onClose={onClose} className="items-center justify-center ">
      <div className="w-auto min-w-[700px]">
        <h3 className="text-center my-5 font-bold text-3xl">Deliveries</h3>
        <div className="flex justify-center">
          <img
            className="object-cover w-full h-52 w-52 rounded-full"
            src={selectedItem.driver && selectedItem.driver.profilePhoto}
          />
        </div>
        <div className="flex flex-row items-center bg-white rounded-lg border shadow-md p-3 mx-5 mt-4">
          <div className="flex flex-col justify-end p-4 leading-normal">
            <p className="mb-3 font-semibold text-gray-800">Driver Id:</p>
            <p className="mb-3 font-semibold text-gray-800">Name:</p>
            <p className="mb-3 font-semibold text-gray-800">Mobile Number:</p>
          </div>
          <div className="flex flex-col justify-end p-4 leading-normal">
            <p className="mb-3 font-normal text-gray-800">{selectedItem.id}</p>
            <p className="mb-3 font-normal text-gray-800">
              {selectedItem.driver && selectedItem.driver.name}
            </p>
            <p className="mb-3 font-normal text-gray-800">{selectedItem.driverMobileNumber}</p>
          </div>
        </div>

        <h3 className="text-center my-5 font-bold text-3xl">Delivery Information</h3>
        <div className="border shadow-md p-3 mx-5 mt-4 rounded-lg">
          <h3 className="text-start my-1 font-bold">Pick Up Info: </h3>
          <div className="flex flex-row items-center bg-white">
            <div className="flex flex-col justify-end p-4 leading-normal">
              <p className="mb-3 font-semibold text-gray-800">Contact Number:</p>
              <p className="mb-3 font-semibold text-gray-800">Address:</p>
            </div>
            <div className="flex flex-col justify-end p-4 leading-normal">
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.pickup && selectedItem.pickup.contactNumber}
              </p>
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.pickup && selectedItem.pickup.formattedAddress}
              </p>
            </div>
          </div>
          <h3 className="text-start my-1 font-bold">Drop Off Info: </h3>
          <div className="flex flex-row items-center bg-white">
            <div className="flex flex-col justify-end p-4 leading-normal">
              <p className="mb-3 font-semibold text-gray-800">Contact Number:</p>
              <p className="mb-3 font-semibold text-gray-800">Address:</p>
            </div>
            <div className="flex flex-col justify-end p-4 leading-normal">
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.dropPoint && selectedItem.dropPoint.contactNumber}
              </p>
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.dropPoint && selectedItem.dropPoint.formattedAddress}
              </p>
            </div>
          </div>
          <h3 className="text-start my-1 font-bold">Additional Details: </h3>
          <div className="flex flex-row justify-between bg-white">
            <div className="flex flex-col p-4 leading-normal">
              <p className="mb-3 font-semibold text-gray-800">Status:</p>
              <p className="mb-3 font-semibold text-gray-800">Price:</p>
              <p className="mb-3 font-semibold text-gray-800">Distance:</p>
              <p className="mb-3 font-semibold text-gray-800">Waiting Charge:</p>
              {selectedItem.status === 'COMPLETED' && (
                <p className="mb-3 font-semibold text-gray-800">Duration:</p>
              )}
            </div>
            <div className="flex flex-col p-4 leading-normal">
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.status && selectedItem.status}
              </p>
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.status && selectedItem.price}
              </p>
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.status && selectedItem.distance}
              </p>
              <p className="mb-3 font-normal text-gray-800">{selectedItem.waitingCharge}</p>
              {selectedItem.status === 'COMPLETED' && (
                <p className="mb-3 font-normal text-gray-800">{selectedItem.duration}</p>
              )}
            </div>
            <div className="flex flex-col p-4 leading-normal">
              <p className="mb-3 font-semibold text-gray-800">Pick Up Arrival Time:</p>
              <p className="mb-3 font-semibold text-gray-800">Pick Up Time:</p>
              <p className="mb-3 font-semibold text-gray-800">Estimated Time:</p>
              <p className="mb-3 font-semibold text-gray-800">Drop Arrival Time:</p>
              {selectedItem.status === 'COMPLETED' && (
                <p className="mb-3 font-semibold text-gray-800">Completed Time:</p>
              )}
            </div>
            <div className="flex flex-col p-4 leading-normal">
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.status && selectedItem.pickupArrivalTime}
              </p>
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.status && selectedItem.pickupTime}
              </p>
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.status && selectedItem.estimatedTime}
              </p>
              <p className="mb-3 font-normal text-gray-800">
                {selectedItem.status && selectedItem.dropArrivalTime}
              </p>
              {selectedItem.status === 'COMPLETED' && (
                <p className="mb-3 font-normal text-gray-800">
                  {selectedItem.status && selectedItem.completedTime}
                </p>
              )}
            </div>
          </div>
        </div>

        {selectedItem.status === 'COMPLETED' && (
          <div>
            <h3 className="text-center my-5 font-bold text-3xl">Proof of Delivery</h3>
            <div className="border shadow-md p-3 mx-5 mt-4 rounded-lg">
              <div className="flex flex-row items-center bg-white justify-center">
                {selectedItem.pickupProof && (
                  <div className="flex flex-col justify-end p-4 leading-normal">
                    <p className="mb-3 font-semibold text-gray-800">Pickup:</p>
                    <img
                      className="object-cover w-full h-64 w-64 rounded-md"
                      src={selectedItem.pickupProof}
                    />
                  </div>
                )}
                <div className="flex flex-col justify-end p-4 leading-normal">
                  <p className="mb-3 font-semibold text-gray-800">Drop:</p>
                  <img
                    className="object-cover w-full h-64 w-64 rounded-md"
                    src={selectedItem.proof.photo}
                  />
                </div>
                <div className="flex flex-col justify-end p-4 leading-normal">
                  <p className="mb-3 font-semibold text-gray-800">Signature:</p>
                  <img
                    className="object-cover w-full h-64 w-64 rounded-md"
                    src={selectedItem.proof.sign}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="my-8 mx-24 flex flex-col justify-center">
          <Button text="Check Deliveries" onClick={onDetails} buttonStyle="rounded-3xl" />
        </div>
      </div>
    </Modal>
  )
}

export const DeliveriesPage = () => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState('in_transit')
  const [isProcessing, setIsProcessing] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  const onDetails = () => {
    window.open(
      `${TRACKER_URL}${selectedItem.id}`,
      '_blank'
    )
  }

  const rowEvents = {
    onClick: (e, row) => {
      setSelectedItem(row)
      setIsModalVisible(true)
    }
  }

  const closeModal = () => setIsModalVisible(false)

  const tableFields = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true
    },
    {
      dataField: 'createTs',
      text: 'Created Time'
    },
    {
      dataField: 'createdBy',
      text: 'User',
      formatter: (cell) => (
        <div className="flex flex-col">
          <div className="flex justify-center">
            <img src={cell.profilePhoto} className="h-16 w-16 rounded-full" />
          </div>
        </div>
      ),
      csvFormatter: (cell, row, rowIndex) => cell.name
    },
    {
      dataField: 'mobileNumber',
      text: 'Mobile Number',
      filter: textFilter(),
      csvFormatter: (cell, row, rowIndex) => cell
    },
    {
      dataField: 'pickup',
      text: 'Pick Up',
      formatter: (cell) => (
        <div className="flex flex-col">
          <span className="font-semibold text-2xl text-gray-800">{cell.formattedAddress}</span>
          <span className="text-lg text-gray-500">{cell.fullAddress}</span>
        </div>
      ),
      csvFormatter: (cell, row, rowIndex) => cell.formattedAddress
    },
    {
      dataField: 'dropPoint',
      text: 'Drop Point',
      formatter: (cell) => (
        <div className="flex flex-col">
          <span className="font-semibold text-2xl text-gray-800">{cell.formattedAddress}</span>
          <span className="text-lg text-gray-500">{cell.fullAddress}</span>
        </div>
      ),
      csvFormatter: (cell, row, rowIndex) => cell.formattedAddress
    },
    {
      dataField: 'distance',
      text: 'Distance'
    },
    {
      dataField: 'price',
      text: 'Price',
      csvFormatter: (cell, row, rowIndex) => cell.replace('₱', 'PHP ')
    },
    {
      dataField: 'waitingCharge',
      text: 'Waiting Charge',
      csvFormatter: (cell, row, rowIndex) => cell.replace('₱', 'PHP ')
    },
    {
      dataField: 'paymentType',
      text: 'Payment Type'
    },
    {
      dataField: 'pickupTime',
      text: 'Pickup Time'
    },
    {
      dataField: 'estimatedTime',
      text: 'Estimated Time'
    },
    {
      dataField: 'completedTime',
      text: 'Completed Time'
    },
    {
      dataField: 'duration',
      text: 'Duration'
    },
    {
      dataField: 'driver',
      text: 'Driver',
      formatter: (cell) => (
        <div className="flex flex-col">
          <div className="flex justify-center">
            <img src={cell.profilePhoto} className="h-16 w-16 rounded-full" />
          </div>
        </div>
      ),
      csvFormatter: (cell, row, rowIndex) => cell.name
    },
    {
      dataField: 'driverMobileNumber',
      text: 'Driver Mobile Number',
      filter: textFilter(),
      csvFormatter: (cell, row, rowIndex) => cell
    },
    {
      dataField: 'note',
      text: 'Note'
    },
    {
      dataField: 'status',
      text: 'Status'
    }
  ]

  const options = [
    { value: 'waiting', label: 'Waiting' },
    { value: 'in_transit', label: 'In Transit' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' }
  ]

  const onStatusChange = (data) => {
    setStatus(data.value)
  }

  useEffect(() => {
    if (!status) return

    setIsProcessing(true)
    setData([])

    getDeliveries({ status })
      .then((res) => {
        if (res.data?.status === 'success') {
          setData(
            res.data?.data.map((e) => {
              return {
                id: e.id,
                createdBy: {
                  profilePhoto: e.createdBy.profilePhoto,
                  name: `${e.createdBy.firstname || ''} ${e.createdBy.lastname || ''}`.trim()
                },
                mobileNumber: e.createdBy.mobileNumber,
                driverMobileNumber: e.driver?.mobileNumber,
                pickup: e.origin,
                dropPoint: e.destination,
                distance: e.distanceText,
                price: `₱${parseFloat(e.price).toFixed(2)}`,
                paymentType: e.paymentType.toUpperCase(),
                status: e.status.toUpperCase(),
                note: e.note,
                proof: e.proof,
                pickupProof: e.pickupProof,
                waitingCharge: `₱${parseFloat(e.waitingCharge).toFixed(2)}`,
                pickupTime: e.pickupTime
                  ? moment(e.pickupTime).local().format('YYYY-MM-DD HH:mm:ss')
                  : '',
                pickupArrivalTime: e.pickupArrivalTime
                  ? moment(e.pickupArrivalTime).local().format('YYYY-MM-DD HH:mm:ss')
                  : '',
                dropArrivalTime: e.dropArrivalTime
                  ? moment(e.dropArrivalTime).local().format('YYYY-MM-DD HH:mm:ss')
                  : '',
                estimatedTime: e.estimatedTime
                  ? moment(e.estimatedTime).local().format('YYYY-MM-DD HH:mm:ss')
                  : '',
                completedTime: e.completedTime
                  ? moment(e.completedTime).local().format('YYYY-MM-DD HH:mm:ss')
                  : '',
                createTs: e.createTs
                  ? moment(e.createTs).local().format('YYYY-MM-DD HH:mm:ss')
                  : '',
                driver: {
                  profilePhoto: e.driver?.profilePhoto,
                  name: e.driver?.name
                },
                duration:
                  e.pickupTime && e.completedTime
                    ? moment.utc(moment(e.completedTime) - moment(e.pickupTime)).format('HH:mm:ss')
                    : ''
              }
            })
          )
        }
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }, [status])

  return (
    <div className="flex bg-gray-100 text-gray-800">
      <div className="flex flex-col w-full justify-center">
        <Select
          className="w-[250px] mb-4 rounded-xl"
          options={options}
          defaultValue={options[1]}
          placeholder="Select Status..."
          onChange={onStatusChange}
          isDisabled={isProcessing}
        />
        <DataTable
          data={data}
          fields={tableFields}
          rowEvents={rowEvents}
          rowStyle={{ verticalAlign: 'middle' }}
        />
        <FloatingModal
          show={isModalVisible}
          onClose={closeModal}
          onDetails={onDetails}
          processing={isProcessing}
          selectedItem={selectedItem}
        />
      </div>
    </div>
  )
}
