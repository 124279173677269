import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import icons from '../assets/icons'
import { Modal } from './modal.component'
import { Button } from './button.component'
import {
  getProfile,
  putProfileNamePic,
  putProfilePassword,
  resizeFile,
  getUploadUrl
} from '../store/actions/app.action'
import { toast } from 'react-toastify'

const UserSettingModal = ({ onData, onClose, onProfile, show }) => {
  const navigate = useNavigate()
  return (
    <div
      onClose={onClose}
      className={`z-10 items-center justify-center absolute right-5 bg-white max-h-[calc(100vh-100px)] rounded-md overflow-y-auto shadow-md mt-10 ${
        show ? '' : 'hidden'
      }`}>
      <div className="w-auto min-w-[300px]" onMouseLeave={onClose}>
        <div className="m-4 flex flex-col divide-y divide-secondary-300">
          <div className="p-3 flex flex-row justify-between">
            <div></div>
            <div className="font-bold text-3xl text-primary-600">Account</div>
            <div className="text-2xl text-secondary-600 cursor-pointer" onClick={onClose}>
              x
            </div>
          </div>
          <div className="p-3 flex flex-row">
            <div className="m-1 h-[40px] w-[40px]">
              <img
                src={onData.profilePhoto}
                className="object-contain h-[40px] w-fit bg-gray-200 border rounded-full"
              />
            </div>
            <span className="flex flex-col ml-2">
              <span className="no-underline font-bold text-2xl text-secondary-600 tracking-wide">
                {onData.name ? onData.name.toUpperCase() : ''}
              </span>
              <span className="no-underline text-secondary-500 text-lg mt-1">
                {onData.username}
              </span>
            </span>
          </div>
          <span
            className="p-3 cursor-pointer text-secondary-500 hover:bg-gray-100 hover:text-gray-700 text-m mt-2"
            onClick={onProfile}>
            <a className="flex items-center">
              <span>
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="-1 -1 24 24"
                  stroke="currentColor"
                  className="h-6 w-6">
                  <path d={icons['setting']} />
                </svg>
              </span>
              <span className="ml-3">Setting</span>
            </a>
          </span>
          <span
            className="p-3 cursor-pointer text-secondary-500 hover:bg-gray-100 hover:text-gray-700 text-m mt-2"
            onClick={() => {
              localStorage.clear()
              navigate('/login')
            }}>
            <a className="flex items-center">
              <span>
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="-1 -1 24 24"
                  stroke="currentColor"
                  className="h-6 w-6">
                  <path d={icons['power']} />
                </svg>
              </span>
              <span className="ml-3">Logout</span>
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

const FloatingModal = ({
  onClose,
  onData,
  getInputValue,
  onConfirm,
  processing = false,
  show = false,
  handleImageChange,
  selectedFile
}) => {
  return (
    <Modal show={show} onClose={onClose} className="items-center justify-center">
      <div className="w-auto min-w-[400px]">
        <div className="modal-header flex items-center justify-center p-4 border-b border-gray-200">
          <h5 className="text-5xl font-bold leading-normal text-gray-800">Account Setting</h5>
        </div>
        <div className="p-4 m-10">
          <div className="grid items-baseline ">
            <div className="m-3 h-[80px] grid grid-rows justify-items-center">
              <div className="relative group">
                <label htmlFor="file-input" className="cursor-pointer">
                  <img
                    src={
                      selectedFile.imagePreviewUrl !== ''
                        ? selectedFile.imagePreviewUrl
                        : onData.profilePhoto
                    }
                    className="block object-fill h-[80px] w-[80px] bg-gray-200 border rounded-full"
                  />
                  <div className="absolute bottom-0 right-0 w-[20px] h-[20px]">
                    <svg
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                      viewBox="-1 -1 24 24"
                      stroke="currentColor"
                      className="">
                      <path d={icons['editImg']} />
                    </svg>
                  </div>
                </label>
                <input
                  className="hidden"
                  id="file-input"
                  type="file"
                  onChange={handleImageChange}
                />
              </div>
            </div>
            <div className="m-3 grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                User Name:{' '}
              </label>
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                {onData.name ? onData.name.toUpperCase() : ''}
              </label>
            </div>
            <div className="m-3 grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                Change Name:{' '}
              </label>
              <input
                name="_name"
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue=""
                onChange={getInputValue}
              />
            </div>
            <div className="m-5 text-center text-1xl font-bold leading-normal text-gray-800">
              CHANGE PASSWORD
            </div>
            <div className="m-3 grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                Input Old Password:{' '}
              </label>
              <input
                name="_oldpassword"
                type="password"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue=""
                onChange={getInputValue}
              />
            </div>
            <div className="m-3 grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                Input New Password:{' '}
              </label>
              <input
                name="_password"
                type="password"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue=""
                onChange={getInputValue}
              />
            </div>
            <div className="m-3 grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">
                Confirm Password:{' '}
              </label>
              <input
                name="_confpassword"
                type="password"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue=""
                onChange={getInputValue}
              />
            </div>
          </div>
        </div>

        <div className="my-8 mx-24 flex flex-row justify-center">
          <Button
            text="SAVE"
            variant="primary"
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            onClick={onConfirm}
            disabled={processing}
          />
          <Button
            text="CANCEL"
            variant="secondary"
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            onClick={onClose}
            disabled={processing}
          />
        </div>
      </div>
    </Modal>
  )
}

export const UserModal = () => {
  const [isSettingVisible, setIsSettingVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [data, setData] = useState({})
  const [selectedFile, setSelectedFile] = useState({ file: '', imagePreviewUrl: '' })

  const closeModal = () => {
    clearUserDetails()
    setIsModalVisible(false)
  }
  const profileBtn = () => {
    setIsModalVisible(true)
  }
  const closeSettingModal = () => {
    setIsSettingVisible(false)
  }
  const settingBtn = () => {
    setIsSettingVisible(true)
  }
  const getInputValue = (e) => {
    userDetails[e.target.name] = e.target.value
  }

  useEffect(() => {
    getProfile().then((res) => {
      if (res.data?.status === 'success') {
        setData({
          id: res.data.data.id,
          name: res.data.data.name,
          username: res.data.data.username,
          profilePhoto: res.data.data.profilePhoto,
          type: res.data.data.type
        })
      }
    })
  }, [])

  const handleImageChange = async (e) => {
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      setSelectedFile({
        file: file,
        imagePreviewUrl: reader.result
      })
    }
    reader.readAsDataURL(file)
  }

  const onConfirm = async () => {
    setIsProcessing(true)
    if (!validation()) {
      setIsProcessing(false)
      return
    }
    if (userDetails['_oldpassword'] !== '' && userDetails['_password'] !== '') {
      putProfilePassword({
        oldPassword: userDetails['_oldpassword'],
        password: userDetails['_password']
      })
        .then((res) => {
          if (res.data?.status === 'success') {
            toast.success('Successfully Updated')
            closeModal()
          } else {
            toast.error('Error incorrect password details!')
          }
        })
        .finally(() => {
          setIsProcessing(false)
        })
    }

    if (userDetails['_name'] !== '' && selectedFile.imagePreviewUrl !== '') {
      let file = selectedFile.file
      let image = await resizeFile(file, 50, 50, 'blob')
      await getUploadUrl().then((res) => {
        if (res.data?.status === 'success') {
          let uploadUrl = res.data.data.profile
          let blobContent = image
          fetch(uploadUrl, {
            method: 'PUT',
            body: blobContent
          }).then((res) => {
            if (res.status === 200) {
              let activeurl = res.url
              let mainurl = activeurl.split('?')[0]
              userDetails['_profilephoto'] = mainurl.split('.com/')[1]
              putProfileNamePic({
                name: userDetails['_name'],
                profilePhoto: userDetails['_profilephoto']
              })
                .then((res) => {
                  if (res.data?.status === 'success') {
                    setData({
                      id: res.data.data.id,
                      name: res.data.data.name,
                      username: res.data.data.username,
                      profilePhoto: res.data.data.profilePhoto,
                      type: res.data.data.type
                    })
                    toast.success('Successfully Updated')
                    closeModal()
                  } else {
                    toast.error('Error confirming profile!')
                  }
                })
                .finally(() => {
                  setIsProcessing(false)
                  window.location.reload(false)
                })
            }
          })
        }
      })
      return
    }

    if (userDetails['_name'] !== '') {
      putProfileNamePic({
        name: userDetails['_name']
      })
        .then((res) => {
          if (res.data?.status === 'success') {
            setData({
              id: res.data.data.id,
              name: res.data.data.name,
              username: res.data.data.username,
              profilePhoto: res.data.data.profilePhoto,
              type: res.data.data.type
            })
            toast.success('Successfully Updated')
            closeModal()
          } else {
            toast.error('Error confirming profile!')
          }
        })
        .finally(() => {
          setIsProcessing(false)
        })
      return
    }

    if (selectedFile.imagePreviewUrl !== '') {
      let file = selectedFile.file
      let image = await resizeFile(file, 50, 50, 'blob')
      await getUploadUrl().then((res) => {
        if (res.data?.status === 'success') {
          let uploadUrl = res.data.data.profile
          let blobContent = image
          fetch(uploadUrl, {
            method: 'PUT',
            body: blobContent
          }).then((res) => {
            if (res.status === 200) {
              let activeurl = res.url
              let mainurl = activeurl.split('?')[0]
              userDetails['_profilephoto'] = mainurl.split('.com/')[1]
              putProfileNamePic({
                name: data.name,
                profilePhoto: userDetails['_profilephoto']
              })
                .then((res) => {
                  if (res.data?.status === 'success') {
                    setData({
                      id: res.data.data.id,
                      name: res.data.data.name,
                      username: res.data.data.username,
                      profilePhoto: res.data.data.profilePhoto,
                      type: res.data.data.type
                    })
                    toast.success('Successfully Updated')
                    closeModal()
                  } else {
                    toast.error('Error confirming profile!')
                  }
                })
                .finally(() => {
                  setIsProcessing(false)
                  window.location.reload(false)
                })
            }
          })
        }
      })
      return
    }
  }

  const validation = () => {
    let val = true
    if (userDetails['_name'] === '') {
      setIsProcessing(false)
    }
    if (userDetails['_profilephoto'] === '') {
      setIsProcessing(false)
    }

    if (userDetails['_oldpassword'] !== '') {
      if (userDetails['_password'] === '' && userDetails['_confpassword'] === '') {
        toast.warning('Please Input new password and confirm password')
        val = false
      } else if (userDetails['_password'] !== userDetails['_confpassword']) {
        toast.warning('Input New Password and Confirm Password did not match')
        val = false
      } else if (userDetails['_password'] === '') {
        toast.warning('Input New Password')
        val = false
      } else if (userDetails['_confpassword'] === '') {
        toast.warning('Input Confirm Password')
        val = false
      }
    }
    return val
  }

  const clearUserDetails = () => {
    userDetails['_name'] = ''
    userDetails['_uname'] = ''
    userDetails['_password'] = ''
    userDetails['_oldpassword'] = ''
    userDetails['_confpassword'] = ''
    userDetails['_profilephoto'] = ''
    setSelectedFile({ file: '', imagePreviewUrl: '' })
  }

  const userDetails = {
    _name: '',
    _uname: '',
    _oldpassword: '',
    _password: '',
    _confpassword: '',
    _profilephoto: ''
  }

  return (
    <div>
      <FloatingModal
        show={isModalVisible}
        onData={data}
        processing={isProcessing}
        onConfirm={onConfirm}
        getInputValue={getInputValue}
        onClose={closeModal}
        handleImageChange={handleImageChange}
        selectedFile={selectedFile}
      />
      <UserSettingModal
        show={isSettingVisible}
        onClose={closeSettingModal}
        onProfile={profileBtn}
        onData={data}
      />
      <div className="flex flex-row items-center cursor-pointer" onMouseEnter={settingBtn}>
        <div className="m-1 h-[50px] w-[50px]">
          <img
            src={data.profilePhoto}
            className="object-contain h-[50px] w-fit bg-gray-200 border rounded-full"
          />
        </div>
        <span className="flex flex-col ml-2">
          <span className="no-underline font-bold text-2xl text-secondary-600 tracking-wide">
            {data.name ? data.name.toUpperCase() : ''}
          </span>
          <span className="flex justify-between">
            <span className="no-underline text-secondary-500 text-lg mt-1">{data.username}</span>
            <span className="no-underline text-secondary-500 text-lg mt-1">▼</span>
          </span>
        </span>
      </div>
    </div>
  )
}
