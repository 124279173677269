import React from 'react'
import { Provider } from 'react-redux'
import { store } from './store'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './App.css'
import { NotFound } from './pages/not-found.page'
import { HomePage } from './pages/home'
import { HomeTemplate } from './components/home-template.component'
import { OnlineApplicationPage } from './pages/online-applications'
import { AdminUserPage } from './pages/admin-users'
import { ConfigPage } from './pages/config'
import { TopupPage } from './pages/topup'

import { ProtectedRoutes, NoSessionRoutes } from './protectedroutes'
import { UsersPage } from './pages/users.page'
import { DriversPage } from './pages/drivers.page'
import { DeliveriesPage } from './pages/deliveries'
import { DriverlocationPage } from './pages/driver-location.page'
import { TicketPage } from './pages/tickets'
import { TicketTypeCategoryPage } from './pages/ticket-type-and-category'
import { VehiclesPage } from './pages/vehicles'
import { UserBalancePage } from './pages/user-balance.page'
import { DriverBalancePage } from './pages/driver-balance.page'
import { AdminRolePage } from './pages/admin-roles'
import { LoginPage } from './v2/pages/login'
import { AdminUserList } from './v2/pages/admin-user'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route element={<NoSessionRoutes />}>
            <Route exact path="/login" element={<LoginPage />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route exact path="/" element={<HomeTemplate component={<HomePage />} />} />
            <Route exact path="/home" element={<HomeTemplate component={<HomePage />} />} />
            <Route
              exact
              path="/online-applications"
              element={
                <HomeTemplate
                  component={<OnlineApplicationPage />}
                  headerName="Online Applications"
                />
              }
            />
            <Route
              exact
              path="/users"
              element={<HomeTemplate component={<UsersPage />} headerName="Users" />}
            />
            <Route
              exact
              path="/drivers"
              element={<HomeTemplate component={<DriversPage />} headerName="Drivers" />}
            />
            <Route
              exact
              path="/deliveries"
              element={<HomeTemplate component={<DeliveriesPage />} headerName="Deliveries" />}
            />
            <Route
              exact
              path="/driver-location"
              element={
                <HomeTemplate component={<DriverlocationPage />} headerName="Driver's Location" />
              }
            />
            <Route
              exact
              path="/admin-users"
              element={<HomeTemplate component={<AdminUserList />} headerName="Admin users" />}
            />
            <Route
              exact
              path="/admin-roles"
              element={<HomeTemplate component={<AdminRolePage />} headerName="Admin Roles" />}
            />
            <Route
              exact
              path="/config"
              element={<HomeTemplate component={<ConfigPage />} headerName="Config" />}
            />
            <Route
              exact
              path="/topup"
              element={<HomeTemplate component={<TopupPage />} headerName="Topup" />}
            />
            <Route
              exact
              path="/tickets"
              element={<HomeTemplate component={<TicketPage />} headerName="Tickets" />}
            />
            <Route
              exact
              path="/ticket-type-and-category"
              element={
                <HomeTemplate
                  component={<TicketTypeCategoryPage />}
                  headerName="Ticket Type and Category"
                />
              }
            />
            <Route
              exact
              path="/vehicles"
              element={<HomeTemplate component={<VehiclesPage />} headerName="Vehicles" />}
            />
            <Route
              exact
              path="/users/balance"
              element={<HomeTemplate component={<UserBalancePage />} headerName="User Balance" />}
            />
            <Route
              exact
              path="/drivers/balance"
              element={
                <HomeTemplate component={<DriverBalancePage />} headerName="Driver Balance" />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer position="top-right" autoClose={3000} closeOnClick />
    </Provider>
  )
}

export default App
