import { useEffect, useState } from 'react'
import { textFilter } from 'react-bootstrap-table2-filter'
import { toast } from 'react-toastify'
import { Button } from '../../components/button.component'
import { DataTable } from '../../components/datatable.component'
import { Modal } from '../../components/modal.component'
import {
  createAdminRole,
  getAdminRoles,
  getProfile,
  updateRolePermission
} from '../../store/actions/app.action'

const FloatingModal = ({
  show = false,
  onClose,
  onConfirm,
  processing = false,
  selectedItem,
  permissions,
  isAddRole,
  getInputValue
}) => {
  return (
    <Modal show={show} onClose={onClose} className="items-center justify-center">
      <div className="w-auto min-w-[400px]">
        <div className="modal-header flex items-center justify-center p-4 border-b border-gray-200">
          <h5 className="text-5xl font-bold leading-normal text-gray-800">
            {isAddRole ? 'Add Role' : 'Update Role'}
          </h5>
        </div>

        <div className="p-4 m-10">
          <div className="grid items-baseline ">
            <div className="grid grid-cols-2 items-baseline mb-2">
              <label className="block font-medium text-gray-600 whitespace-nowrap">Name: </label>
              <input
                name="name"
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                disabled={!isAddRole}
                defaultValue={selectedItem.name}
                onChange={getInputValue}
              />
            </div>
            <div>
              {permissions.map((e) => (
                <div key={e} className="grid grid-cols-2 items-baseline">
                  <label className="block font-medium text-gray-600 whitespace-nowrap capitalize">
                    {e.replace(/_/g, ' ')}
                  </label>
                  <input
                    name={e}
                    type="checkbox"
                    className=""
                    checked={selectedItem[e]}
                    onChange={getInputValue}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="my-8 mx-24 flex flex-row justify-center">
          <Button
            text="SAVE"
            variant="primary"
            onClick={onConfirm}
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            disabled={processing}
          />
          <Button
            text="CANCEL"
            variant="secondary"
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  )
}

export const AdminRolePage = () => {
  const [data, setData] = useState([])
  const [profile, setProfile] = useState()
  const [isAddRole, setisAddRole] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [reload, setReload] = useState(false)
  const rowClasses = ''
  const events = {
    onClick: (e, column, columnIndex, row) => {
      setSelectedItem(row)
      setIsModalVisible(true)
    }
  }

  const checkboxFormatter = (cell) => {
    return (
      <span className="flex justify-center">
        <input type="checkbox" checked={cell} onChange={() => {}} />
      </span>
    )
  }

  const getInputValue = (e) => {
    if (e.target.name === 'name') {
      setSelectedItem({ ...selectedItem, [e.target.name]: e.target.value })
    } else {
      setSelectedItem({ ...selectedItem, [e.target.name]: e.target.checked })
    }
  }

  const clearUserRole = () => {
    setSelectedItem({})
  }

  const permissions = [
    'dashboard',
    'add_balance',
    'add_bouser',
    'user_list',
    'driver_list',
    'driver_location',
    'online_application',
    'delivery_list',
    'ticket',
    'config',
    'topup_items',
    'ticket_types_and_categories',
    'vehicles'
  ]

  const roles = ['superadmin']

  const tableFields = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true,
      events
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter(),
      events
    }
  ].concat(
    permissions.map((e) => ({
      dataField: e,
      text: e.replace(/_/g, ' '),
      formatter: checkboxFormatter,
      events
    }))
  )

  const closeModal = () => {
    setIsModalVisible(false)
    setisAddRole(false)
    clearUserRole()
  }
  const validation = () => {
    let val = true
    if (isAddRole) {
      if (!selectedItem.name) {
        toast.warning('Enter role name.')
        val = false
      }
    }
    return val
  }

  const onConfirm = () => {
    setIsProcessing(true)
    if (!validation()) {
      setIsProcessing(false)
      return
    }

    if (!isAddRole) {
      updateRolePermission(selectedItem.id, {
        permissions: permissions.reduce((data, e) => {
          return {
            ...data,
            [e]: !!selectedItem[e]
          }
        }, {})
      })
        .then((res) => {
          if (res.data?.status === 'success') {
            toast.success('Successfully Updated Role')
            setReload(!reload)
            closeModal()
          } else {
            toast.error(res.data?.message || 'Error updating role!')
          }
        })
        .finally(() => {
          setIsProcessing(false)
        })
    } else {
      createAdminRole({
        name: selectedItem.name,
        permissions: permissions.reduce((data, e) => {
          return {
            ...data,
            [e]: !!selectedItem[e]
          }
        }, {})
      })
        .then((res) => {
          if (res.data?.status === 'success') {
            toast.success('Successfully Added New Role')
            setReload(!reload)
            closeModal()
          } else {
            toast.error('Error adding new role!')
          }
        })
        .finally(() => {
          setIsProcessing(false)
        })
    }
  }

  useEffect(() => {
    getProfile().then((res) => setProfile(res.data.data))
  }, [])

  useEffect(() => {
    getAdminRoles().then((res) => {
      if (res.data?.status === 'success') {
        setData(
          res.data.data.map((e) => {
            return {
              id: e.id,
              name: e.name,
              ...e.permissions
            }
          })
        )
      }
    })
  }, [reload])

  return (
    <>
      {profile && !roles.includes(profile.role?.name) && (
        <span style={{ color: 'red' }}>Not Authorized!!!</span>
      )}
      {profile && roles.includes(profile.role?.name) && (
        <div className="flex bg-gray-100 text-gray-800">
          <div className="flex w-full justify-center flex-col">
            <Button
              text="Add Role"
              variant="secondary"
              buttonStyle="w-[125px] mb-4 rounded-xl"
              icon="add"
              onClick={() => {
                setIsModalVisible(true)
                setisAddRole(true)
              }}
            />
            <DataTable data={data} fields={tableFields} rowClasses={rowClasses} />
            <FloatingModal
              show={isModalVisible}
              onClose={closeModal}
              onConfirm={onConfirm}
              processing={isProcessing}
              selectedItem={selectedItem}
              permissions={permissions}
              isAddRole={isAddRole}
              getInputValue={getInputValue}
            />
          </div>
        </div>
      )}
    </>
  )
}
