import { Button, Input, Form } from 'antd';
import SIDE_IMG from '../../assets/login-img.png';
import React from 'react';
import { login } from '../../../store/actions/app.action';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

type LoginFormData = {
  loginid: string;
  password: string;
};

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const onLogin = (data: LoginFormData) => {
    login(data).then((res) => {
      if (res.data?.status === 'success') {
        localStorage.setItem('token', res.data.data.token);
        navigate(location.state?.from ? location.state.from : '/');
      } else {
        toast.error('Error Invalid Credentials!');
      }
    });
  };

  return (
    <div className="flex flex-row h-screen">
      <div className="flex flex-col m-auto max-w-[600px]">
        <div className="text-center mb-20">
          <span className="text-[#409EFF] font-black text-[50px]">Nexus</span>
        </div>
        <div className="flex flex-col mx-5 sm:mx-20 xl:mx-40 mb-2">
          <span className="font-light text-4xl">Sign in</span>
          <span className="font-light text-md py-10">
            Please sign in using your working account, which is linked to your active Gmail account.
          </span>
        </div>
        <Form
          className="flex flex-col m-auto font-roboto text-4xl text-text-base mx-5 sm:mx-20 xl:mx-40"
          layout="vertical"
          onFinish={onLogin}
          labelCol={{
            className: 'font-roboto',
          }}
          autoComplete="off">
          <div className="flex flex-col my-10">
            <Form.Item
              label="Username"
              name="loginid"
              className="p-0 m-0 mb-3 font-medium"
              rules={[{ required: true, message: 'Please input your Username!' }]}>
              <Input placeholder="Enter your username" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              className="p-0 m-0 mb-3 mt-5"
              rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input.Password placeholder="Enter your password" autoComplete="false" />
            </Form.Item>
            <div className="flex justify-end">
              <Button className="border-0 w-fit p-0 mt-1 text-text-link text-md">
                Forgot password?
              </Button>
            </div>
          </div>
          <div className="flex flex-col">
            <Button className="text-white bg-blue-700" htmlType="submit">
              Sign In
            </Button>
          </div>
        </Form>
      </div>
      <div className="flex w-fit justify-end">
        <img className="object-fill" src={SIDE_IMG} />
      </div>
    </div>
  );
};
