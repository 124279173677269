import { useEffect, useState } from 'react'
import { Button } from '../../components/button.component'
import { DataTable } from '../../components/datatable.component'
import { Modal } from '../../components/modal.component'
import { getDriverApplications, confirmDriverApplication } from '../../store/actions/app.action'
import { toast } from 'react-toastify'
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter'
import * as moment from 'moment'

const FloatingModal = ({ show = false, onClose, onConfirm, processing = false, selectedItem }) => {
  return (
    <Modal show={show} onClose={onClose} className="items-center justify-center ">
      <div className="w-auto min-w-[700px]">
        <h3 className="text-center my-5 font-bold text-3xl">Driver Information</h3>
        <div className="flex justify-center">
          <img
            className="object-cover w-full h-52 w-52 rounded-full"
            src={selectedItem.profilePhoto}
          />
        </div>
        <div className="flex flex-row items-center bg-white rounded-lg border shadow-md p-3 mx-5 mt-4">
          <div className="flex flex-col justify-end p-4 leading-normal">
            <p className="mb-3 font-semibold text-gray-800">Driver Id:</p>
            <p className="mb-3 font-semibold text-gray-800">Name:</p>
            <p className="mb-3 font-semibold text-gray-800">Mobile Number:</p>
            <p className="mb-3 font-semibold text-gray-800">Email Address:</p>
            <p className="mb-3 font-semibold text-gray-800">Address:</p>
          </div>
          <div className="flex flex-col justify-end p-4 leading-normal">
            <p className="mb-3 font-normal text-gray-800">{selectedItem.id}</p>
            <p className="mb-3 font-normal text-gray-800">{selectedItem.name}</p>
            <p className="mb-3 font-normal text-gray-800">+63{selectedItem.mobileNumber}</p>
            <p className="mb-3 font-normal text-gray-800">{selectedItem.email}</p>
            <p className="mb-3 font-normal text-gray-800">{selectedItem.address}</p>
          </div>
        </div>

        <h3 className="text-center my-5 font-bold text-3xl">Vehicle Information</h3>
        <div className="border shadow-md p-3 mx-5 mt-4 rounded-lg">
          <div className="flex flex-row items-center bg-white">
            <div className="flex flex-col justify-end p-4 leading-normal">
              <p className="mb-3 font-semibold text-gray-800">Type:</p>
              <p className="mb-3 font-semibold text-gray-800">Brand:</p>
              <p className="mb-3 font-semibold text-gray-800">Make:</p>
            </div>
            <div className="flex flex-col justify-end p-4 leading-normal">
              <p className="mb-3 font-normal text-gray-800">{selectedItem.vehicleType}</p>
              <p className="mb-3 font-normal text-gray-800">{selectedItem.vehicleBrand}</p>
              <p className="mb-3 font-normal text-gray-800">{selectedItem.vehicleMake}</p>
            </div>
          </div>
          <div className="flex flex-row items-center bg-white justify-center">
            <div className="flex flex-col justify-end p-4 leading-normal">
              <p className="mb-3 font-semibold text-gray-800">License:</p>
              <img
                className="object-cover w-full h-64 w-64 rounded-md"
                src={selectedItem.license}
              />
            </div>
            <div className="flex flex-col justify-end p-4 leading-normal">
              <p className="mb-3 font-semibold text-gray-800">Selfie:</p>
              <img className="object-cover w-full h-64 w-64 rounded-md" src={selectedItem.selfie} />
            </div>
            <div className="flex flex-col justify-end p-4 leading-normal">
              <p className="mb-3 font-semibold text-gray-800">Image:</p>
              <img
                className="object-cover w-full h-64 w-64 rounded-md"
                src={selectedItem.vehicleImage}
              />
            </div>
          </div>
        </div>
        <div className="my-8 mx-24 flex flex-col justify-center">
          <Button
            text="Confirm"
            onClick={onConfirm}
            buttonStyle="rounded-3xl"
            disabled={processing || selectedItem.status !== 'submitted'}
          />
          <Button text="Cancel" variant="secondary" onClick={onClose} buttonStyle="rounded-3xl" />
        </div>
      </div>
    </Modal>
  )
}

export const OnlineApplicationPage = () => {
  const [data, setData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  const events = {
    onClick: (e, column, columnIndex, row) => {
      setSelectedItem(row)
      setIsModalVisible(true)
    }
  }

  const statusArr = {
    submitted: 'submitted',
    completed: 'completed'
  }

  const tableFields = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true,
      events
    },
    {
      dataField: 'applicationDate',
      text: 'Application Date',
      events
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter(),
      events
    },
    {
      dataField: 'mobileNumber',
      text: 'Mobile Number',
      filter: textFilter(),
      events
    },
    {
      dataField: 'email',
      text: 'Email',
      filter: textFilter(),
      events
    },
    {
      dataField: 'address',
      text: 'Address',
      events
    },
    {
      dataField: 'vehicleBrand',
      text: 'Vehicle Brand',
      events
    },
    {
      dataField: 'vehicleMake',
      text: 'Vehicle Make',
      events
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell) => statusArr[cell],
      filter: selectFilter({
        options: statusArr
      }),
      events
    },
    {
      dataField: 'confirmedBy',
      text: 'Confirmed By',
      events
    },
    {
      dataField: 'confirmedDate',
      text: 'Confirmed Date',
      events
    }
  ]

  const closeModal = () => setIsModalVisible(false)

  const onConfirm = () => {
    setIsProcessing(true)
    confirmDriverApplication({ id: selectedItem.id })
      .then((res) => {
        if (res.data?.status === 'success') {
          toast.success('Successfully confirmed driver application')
          selectedItem.status = 'completed'
        } else {
          toast.error('Error confirming application!')
        }
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  useEffect(() => {
    getDriverApplications().then((res) => {
      if (res.data?.status === 'success') {
        setData(
          res.data.data.map((e) => {
            return {
              applicationDate: moment(e.createTs).local().format('YYYY-MM-DD HH:mm:ss'),
              id: e.id,
              name: e.name,
              mobileNumber: e.mobileNumber,
              email: e.email,
              address: e.address,
              vehicleMake: e.make,
              vehicleBrand: e.brand,
              status: e.status,
              confirmedBy: e.confirmedBy?.name,
              confirmedDate: e.confirmedDate
                ? moment(e.confirmedDate).local().format('YYYY-MM-DD HH:mm:ss')
                : '',
              profilePhoto: e.profileImage,
              license: e.license,
              vehicleImage: e.image,
              selfie: e.selfie,
              vehicleType: e.vehicleType
            }
          })
        )
      }
    })
  }, [])

  return (
    <div className="flex bg-gray-100 text-gray-800">
      <div className="flex w-full justify-center">
        <DataTable data={data} fields={tableFields} />
        <FloatingModal
          show={isModalVisible}
          onClose={closeModal}
          onConfirm={onConfirm}
          processing={isProcessing}
          selectedItem={selectedItem}
        />
      </div>
    </div>
  )
}
