import { Checkbox, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { confirmAdminUsers } from '../../../../store/actions/app.action';

interface CustomProps {
  roles: Record<string, string>[];
  visible: boolean;
  onClose: Function;
}

type CreateAdminData = {
  name: string;
  username: string;
  password: string;
  role: string;
};

export const UserModalComponent: React.FC<CustomProps> = ({
  roles,
  visible,
  onClose,
}: CustomProps) => {
  const [selectedRole, setSelectedRole] = useState();
  const [selectedRolePermission, setSelectedRolePermission] = useState<Record<string, boolean>>({});
  const onCreateAdmin = (data: CreateAdminData) => {
    confirmAdminUsers({
      name: data.name,
      username: data.username.toLowerCase(),
      password: data.password,
      type: 'admin',
      role: data.role,
    }).then((res) => {
      if (res.data?.status === 'success') {
        toast.success('Successfully Added New User');
        window.location.reload();
      } else {
        toast.error(res.data?.message || 'Error confirming new user!');
      }
    });
  };

  return (
    <Modal
      title="Add User"
      className="mt-10 flex flex-col p-10"
      open={visible}
      onCancel={() => onClose()}
      okButtonProps={{
        style: { backgroundColor: 'blue' },
        form: 'create-form',
        htmlType: 'submit',
      }}
      width={640}>
      <Form
        id="create-form"
        labelCol={{ span: 7, className: 'font-roboto' }}
        wrapperCol={{ span: 14 }}
        className="flex flex-col m-auto font-roboto px-2"
        labelAlign="left"
        onFinish={onCreateAdmin}
        autoComplete="off">
        <Form.Item
          label="Name"
          name="name"
          className="p-0 m-0 mb-3 font-medium"
          rules={[{ required: true, message: 'Please input admin name!' }]}>
          <Input placeholder="Enter admin name" />
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          className="p-0 m-0 mb-3 font-medium"
          rules={[
            { required: true, message: 'Please input admin username!' },
            { min: 6, message: 'Username must be minimum 6 characters.' },
            {
              message: 'Must be alphanumeric',
              validator: (_, value) => {
                if (/^[a-zA-Z][a-zA-Z0-9_]*[a-zA-Z0-9]$/.test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error('Invalid format'));
                }
              },
            },
          ]}>
          <Input placeholder="Enter username" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          className="p-0 m-0 mb-3"
          rules={[
            { required: true, message: 'Please input admin password!' },
            { min: 6, message: 'Pasword must be minimum 6 characters.' },
          ]}>
          <Input.Password placeholder="Enter admin password" autoComplete="false" />
        </Form.Item>
        <Form.Item
          label="Account Type"
          name="role"
          className="p-0 m-0 mb-3 font-medium"
          initialValue={selectedRole}
          rules={[{ required: true, message: 'Please select role!' }]}>
          <Select
            showSearch={true}
            className="min-w-[200px]"
            options={roles?.map((e) => ({
              value: e.id,
              label: e.name,
              permissions: e.permissions,
            }))}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value, record: any) => {
              setSelectedRole(value);
              setSelectedRolePermission(record.permissions);
            }}
          />
        </Form.Item>
        <div className="grid grid-cols-2 gap-4 border-2 border-gray-200 rounded-md bg-gray-100 p-4 min-h-[100px] shadow-md">
          <Checkbox className="hidden" />
          {Object.keys(selectedRolePermission).map((e: string, i) => (
            <Checkbox key={i} checked={selectedRolePermission[e]}>
              {e}
            </Checkbox>
          ))}
        </div>
      </Form>
    </Modal>
  );
};
