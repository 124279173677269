import { useEffect, useState } from 'react'
import { Button } from '../../components/button.component'
import { DataTable } from '../../components/datatable.component'
import { Modal } from '../../components/modal.component'
import { putAdminConfig, getAdminConfig } from '../../store/actions/app.action'
import { toast } from 'react-toastify'
import { textFilter } from 'react-bootstrap-table2-filter'

const FloatingModal = ({
  show = false,
  onClose,
  onConfirm,
  processing = false,
  selectedItem,
  isAddUser,
  getInputValue
}) => {
  return (
    <Modal show={show} onClose={onClose} className="items-center justify-center">
      <div className="w-auto min-w-[400px]">
        <div className="modal-header flex items-center justify-center p-4 border-b border-gray-200">
          <h5 className="text-5xl font-bold leading-normal text-gray-800">Update Value</h5>
        </div>

        <div className="p-4 m-10">
          <div className="grid items-baseline ">
            <div className="grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">Name: </label>
              <input
                name="_name"
                type="text"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                disabled={!isAddUser}
                defaultValue={selectedItem.name}
                onChange={getInputValue}
              />
            </div>
            <div className="mt-5 grid grid-cols-2 items-baseline">
              <label className="block font-medium text-gray-600 whitespace-nowrap">Value: </label>
              <input
                name="_value"
                type="number"
                className="font-regular w-full p-2 border border-gray-300 rounded-l shadow"
                defaultValue={selectedItem.value}
                onChange={getInputValue}
              />
            </div>
          </div>
        </div>

        <div className="my-8 mx-24 flex flex-row justify-center">
          <Button
            text="SAVE"
            variant="primary"
            onClick={onConfirm}
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            disabled={processing}
          />
          <Button
            text="CANCEL"
            variant="secondary"
            buttonStyle="min-w-[100px] mb-5 rounded-3xl"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  )
}

export const ConfigPage = () => {
  const [data, setData] = useState([])
  const [isAddUser, setisAddUser] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const rowClasses = ''
  const events = {
    onClick: (e, column, columnIndex, row) => {
      setSelectedItem(row)
      setIsModalVisible(true)
    }
  }

  const getInputValue = (e) => {
    userDetails[e.target.name] = e.target.value
  }

  const clearUserDetails = () => {
    userDetails['_name'] = ''
    userDetails['_value'] = ''
  }

  const userDetails = {
    _name: '',
    _value: ''
  }

  const tableFields = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true,
      events
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter(),
      events
    },
    {
      dataField: 'description',
      text: 'Description',
      filter: textFilter(),
      events
    },
    {
      dataField: 'value',
      text: 'Value',
      events
    },
    {
      dataField: 'updatedBy',
      text: 'Updated By',
      events
    }
  ]

  const closeModal = () => {
    clearUserDetails()
    setIsModalVisible(false)
    setisAddUser(false)
  }
  const validation = () => {
    let val = true

    if (userDetails['_value'] === '') {
      toast.warning('Value is empty')
      val = false
    }

    return val
  }

  const onConfirm = () => {
    setIsProcessing(true)
    if (!validation()) {
      setIsProcessing(false)
      return
    }
    putAdminConfig({
      id: selectedItem.id,
      value: userDetails['_value']
    })
      .then((res) => {
        if (res.data?.status === 'success') {
          getData()
          toast.success('Successfully Updated Value')
          closeModal()
        } else {
          toast.error('Error confirming Value!')
          setIsProcessing(false)
        }
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }
  const getData = () => {
    getAdminConfig().then((res) => {
      if (res.data?.status === 'success') {
        setData(
          res.data.data.map((e) => {
            return {
              id: e.id,
              name: e.name,
              description: e.description,
              value: e.value,
              updatedBy: e.updatedBy?.name
            }
          })
        )
      }
    })
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="flex bg-gray-100 text-gray-800">
      <div className="flex w-full justify-center flex-col">
        <DataTable data={data} fields={tableFields} rowClasses={rowClasses} />
        <FloatingModal
          show={isModalVisible}
          onClose={closeModal}
          onConfirm={onConfirm}
          processing={isProcessing}
          selectedItem={selectedItem}
          isAddUser={isAddUser}
          getInputValue={getInputValue}
        />
      </div>
    </div>
  )
}
