import Resizer from 'react-image-file-resizer'

export default {
  resizeFile: async (file, w, h, content) => {
    let newFile = new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        w,
        h,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        content
      )
    })
    return newFile
  }
}
