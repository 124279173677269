import axios from 'axios'

const API_BASE_URL = 'https://baedal-api.upfront.ph'
// const API_BASE_URL = 'http://localhost:3000'
// const API_BASE_URL = 'https://baedal-api.yourexpert.ph'
export const TRACKER_URL = 'http://upfront-production.s3-website-ap-southeast-1.amazonaws.com/'

const client = axios.create({
  baseURL: API_BASE_URL,
  validateStatus: false
})

export default {
  getFromServer: async (path) => {
    const token = localStorage.getItem('token')
    return await client
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        if (res.data?.statusCode === 401) {
          localStorage.clear()
          window.location.reload(false)
        }
        return res
      })
  },

  postToServer: async (path, data) => {
    const token = localStorage.getItem('token')
    return await client
      .post(path, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        if (res.data?.statusCode === 401) {
          localStorage.clear()
          window.location.reload(false)
        }
        return res
      })
  },

  putToServer: async (path, data) => {
    const token = localStorage.getItem('token')
    return await client
      .put(path, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        if (res.data?.statusCode === 401) {
          localStorage.clear()
          window.location.reload(false)
        }
        return res
      })
  },

  delToServer: async (path, data) => {
    const token = localStorage.getItem('token')
    return await client
      .delete(
        path,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
        data
      )
      .then((res) => {
        if (res.data?.statusCode === 401) {
          localStorage.clear()
          window.location.reload(false)
        }
        return res
      })
  }
}
