import React, { useState, useEffect, useRef } from 'react'
import { GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api'
import drivericon from '../assets/drivericon.png'
import { getDrivers } from '../store/actions/app.action'
import Select from 'react-select'
import _ from 'lodash'

export const DriverlocationPage = () => {
  const [locations, setLocation] = useState([])
  const [filter, setFilter] = useState()
  const [selectDrivers, setSelectDrivers] = useState([])
  const [isZoom, setIsZoom] = useState()
  const [isCenter, setIsCenter] = useState()
  const [shouldRender, setShouldRender] = useState(true)
  const [onHover, setIsOnHover] = useState(false)
  const [currentID, setCurrentID] = useState()
  // const [currentAddress, setCurrentAddress] = useState()
  const componentWillUnmount = useRef(false)

  const google = window.google

  const mapContainerStyle = {
    height: '100%',
    width: '100%'
  }

  const options = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }

  const createKey = (locations) => {
    if (locations !== undefined) {
      return locations.lat + locations.lng
    }
  }

  const selectValue = (data) => {
    setLocation(data)
  }

  useEffect(() => {
    // if (_.isEmpty(locations)) {
    setLocation(selectDrivers)
    // }
  }, [locations, selectDrivers])

  useEffect(() => {
    setIsZoom(14)
    setIsCenter({ lat: 14.533199733231148, lng: 121.02278221119558 })
  }, [])

  const reloadContent = () => {
    getDrivers().then((res) => {
      if (res.data?.status === 'success') {
        setSelectDrivers(
          _.reject(res.data.data, ['lastLocation', null]).map((e) => {
            return {
              lat: e.lastLocation.lat,
              lng: e.lastLocation.lon,
              value: e.name,
              label: e.name,
              status: e.isActive,
              profilePhoto: e.profilePhoto,
              mobileNumber: e.mobileNumber,
              id: e.id
            }
          })
        )
      }
    })
    StartTimer()
  }

  const StartTimer = () => {
    setTimeout(reloadContent, 1000)
  }
  const StopTimer = () => {
    clearTimeout(StartTimer)
  }

  useEffect(() => {
    getDrivers().then((res) => {
      if (res.data?.status === 'success') {
        setSelectDrivers(
          _.reject(res.data.data, ['lastLocation', null]).map((e) => {
            return {
              lat: e.lastLocation.lat,
              lng: e.lastLocation.lon,
              value: e.name,
              label: e.name,
              status: e.isActive,
              profilePhoto: e.profilePhoto,
              mobileNumber: e.mobileNumber,
              id: e.id
            }
          })
        )
      }
    })
    StartTimer()
  }, [])

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      componentWillUnmount.current = true
    }
  }, [])

  useEffect(() => {
    return () => {
      // This line only evaluates to true after the componentWillUnmount happens
      if (componentWillUnmount.current) {
        StopTimer()
        setShouldRender(false)
      }
    }
  }, [shouldRender]) // This dependency guarantees that when the componentWillUnmount fires it will log the latest params

  // const CallGeoCoder = (lat, lng) => {
  //   let geocoder = new google.maps.Geocoder()
  //   let input = lat + ',' + lng
  //   let latlngStr = input.split(',', 2)
  //   let latlng = {
  //     lat: parseFloat(latlngStr[0]),
  //     lng: parseFloat(latlngStr[1])
  //   }
  //   geocoder.geocode({ location: latlng }).then((res) => {
  //     console.log(res)
  //   })
  // }

  return (
    <div className="h-full z-0">
      {/* <pre>{JSON.stringify(locations)}</pre> */}
      <div className="flex flex-row">
        <Select
          closeMenuOnSelect={false}
          className="w-[40%] mb-4 rounded-xl "
          isMulti
          options={selectDrivers}
          onChange={selectValue}
        />
        <Select
          className="w-[20%] mb-4 rounded-xl ml-5"
          options={[
            {
              label: 'Online',
              value: true
            },
            {
              label: 'Offline',
              value: false
            }
          ]}
          placeholder="Status"
          onChange={(value) => setFilter(value?.value)}
          isClearable={true}
        />
      </div>

      <LoadScript googleMapsApiKey="AIzaSyCaAVNjAHDff0JVN_hePSmlOVA9XuhCKO8">
        <GoogleMap
          id="marker"
          mapContainerStyle={mapContainerStyle}
          zoom={isZoom}
          center={isCenter}
          options={{
            streetViewControl: false,
            mapTypeControl: false
          }}>
          <MarkerClusterer options={options}>
            {(clusterer) =>
              locations.filter(e => (filter === undefined || e.status === filter)).map((location) => (
                <Marker
                  onMouseOver={() => {
                    setIsOnHover(true)
                    setCurrentID(location.id)
                    // CallGeoCoder(location.lat, location.lng)
                  }}
                  onMouseOut={() => {
                    setIsOnHover(false)
                    setCurrentID('')
                  }}
                  key={createKey(location)}
                  position={location}
                  clusterer={clusterer}
                  options={{
                    icon: {
                      url: drivericon,
                      scaledSize: new window.google.maps.Size(51 / 2, 101 / 2)
                    }
                  }}>
                  {onHover && location.id === currentID && (
                    <InfoWindow
                      position={{
                        lat: location.lat + location.lat * 0.0001,
                        lng: location.lng
                      }}>
                      <div className="flex flex-row">
                        <div className="relative flex mr-3 justify-center items-center">
                          <img src={location.profilePhoto} className="h-16 w-16 rounded-md" />
                          <div className={`absolute bottom-1 -right-2 h-5 w-5 ${location.status ? 'bg-green-600' : 'bg-gray-300'} border-1 border-gray-500 rounded-full`}></div>
                        </div>
                        <ul className="flex flex-col">
                          <li className="flex flex-row">
                            <div className="text-secondary-600 font-bold mr-3 mb-1">Name: </div>
                            <div>{JSON.parse(JSON.stringify(location.value))}</div>
                          </li>
                          <li className="flex flex-row">
                            <div className="text-secondary-600 font-bold mr-3 mb-1">Mobile Number: </div>
                            <div>{JSON.parse(JSON.stringify(location.mobileNumber))}</div>
                          </li>
                          <li className="flex flex-row">
                            <div className="text-secondary-600 font-bold mr-2 mb-1">Status: </div>
                            <div>{location.status ? 'Online' : 'Offline'}</div>
                          </li>
                          {/* <li className="flex flex-col">
                            <div className="text-secondary-600 font-bold">Location: </div>
                            <div>{currentAddress}</div>
                          </li> */}
                        </ul>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      </LoadScript>
    </div>
  )
}
