import { Navigate, Outlet, useLocation } from 'react-router-dom'
import React from 'react'
// import { LoginPage } from "./pages/login.page";

const useAuth = () => {
  const token = localStorage.getItem('token')
  const user = { loggedIn: token }
  return user && user.loggedIn
}

export const ProtectedRoutes = () => {
  const location = useLocation()
  const isAuth = useAuth()
  return isAuth ? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />
}

export const NoSessionRoutes = () => {
  const isAuth = useAuth()
  return !isAuth ? <Outlet /> : <Navigate to="/" replace />
}
