import apiClientService from '../../services/api-client.service'
import imageUploadService from '../../services/image-upload.service'

export const login = async (payload) => {
  return await apiClientService.postToServer('/admin/auth/login', payload)
}

// Online applications
export const getDriverApplications = async () => {
  return await apiClientService.getFromServer('/driver/applications?size=1000')
}

export const confirmDriverApplication = async (payload) => {
  return await apiClientService.postToServer(`/driver/application/${payload.id}/confirm`, {})
}

// Users
export const getUsers = async () => {
  return await apiClientService.getFromServer('/admin/app-users?size=1000')
}

//Drivers
export const getDrivers = async () => {
  return await apiClientService.getFromServer('/admin/app-drivers?size=1000')
}

//Deliveries
export const getDeliveries = async (payload) => {
  return await apiClientService.getFromServer(
    `/admin/deliveries?size=1000&status=${payload.status}`
  )
}

//Admin Users
export const getAdminUsers = async () => {
  return await apiClientService.getFromServer('/admin/users?size=1000')
}

export const confirmAdminUsers = async (payload) => {
  return await apiClientService.postToServer('/admin/user', payload)
}

export const updateAdminUser = async (id, payload) => {
  return await apiClientService.putToServer(`/admin/user/${id}`, payload)
}

export const resetPassAdminUsers = async (payload) => {
  return await apiClientService.postToServer('/admin/user/reset-password', payload)
}

//Profile
export const getProfile = async () => {
  return await apiClientService.getFromServer('/admin/user/profile')
}

export const putProfileNamePic = async (payload) => {
  return await apiClientService.putToServer('/admin/user/profile', payload)
}

export const putProfilePassword = async (payload) => {
  return await apiClientService.putToServer('/admin/user/password', payload)
}

//Profile Photo
export const getUploadUrl = async () => {
  return await apiClientService.getFromServer('/upload-url/profile-photo')
}

export const resizeFile = async (file, w, h, content) => {
  return await imageUploadService.resizeFile(file, w, h, content)
}

//Config
export const getAdminConfig = async () => {
  return await apiClientService.getFromServer('/admin/configs')
}

export const putAdminConfig = async (payload) => {
  return await apiClientService.putToServer(`/admin/configs/${payload.id}`, payload)
}

//Topup
export const getAdminTopup = async () => {
  return await apiClientService.getFromServer('/admin/topups')
}

export const postAdminTopup = async (payload) => {
  return await apiClientService.postToServer('/admin/topup', payload)
}

export const delAdminTopup = async (payload) => {
  return await apiClientService.delToServer(`/admin/topup/${payload.id}`)
}

export const putAdminTopup = async (payload) => {
  return await apiClientService.putToServer(`/admin/topup/${payload.id}`, payload)
}

//Report
export const getDashboardData = async (payload) => {
  return await apiClientService.getFromServer(
    `/admin/dashboard?startDate=${payload.startDate}&endDate=${payload.endDate}`
  )
}

//Ticketing
export const getTicket = async () => {
  return await apiClientService.getFromServer('/admin/ticket')
}

export const getTicketById = async (payload) => {
  return await apiClientService.getFromServer(`/admin/ticket/${payload.id}`)
}

export const replyToTicket = async (payload) => {
  return await apiClientService.postToServer(`/admin/ticket/${payload.id}/reply`, payload)
}

//Ticketing Types and category
export const getTicketTypeCategory = async () => {
  return await apiClientService.getFromServer('/admin/ticket/types-and-categories')
}

export const createTicketTypeCategory = async (payload) => {
  return await apiClientService.postToServer('/admin/ticket/types-and-categories', payload)
}

export const updateTicketTypeCategoryStatus = async (payload) => {
  return await apiClientService.putToServer(
    `/admin/ticket/types-and-categories/${payload.id}/${payload.status}`
  )
}

// Pricing
export const getVehiclePricing = async () => {
  return await apiClientService.getFromServer('/admin/pricing')
}

export const updateVehiclePricing = async (payload) => {
  return await apiClientService.putToServer(`/admin/pricing/${payload.id}`, payload)
}

// Update balance
export const updateUserBalance = async (payload) => {
  return await apiClientService.postToServer('/admin/app-users/balance', payload)
}

export const updateDriverBalance = async (payload) => {
  return await apiClientService.postToServer('/admin/app-drivers/balance', payload)
}

// Get Roles
export const getAdminRoles = async (payload) => {
  return await apiClientService.getFromServer('/admin/roles?size=1000')
}

export const createAdminRole = async (payload) => {
  return await apiClientService.postToServer('/admin/role', payload)
}

export const updateRolePermission = async (id, payload) => {
  return await apiClientService.putToServer(`/admin/role/${id}`, payload)
}
